import { AuctionBidDto } from '@/features/auction/auctionBid.dto';
import {
  AuctionInfoCreateDto,
  AuctionInfoDto,
  AuctionInfoFullDto,
  normalizeAuctionInfoDto,
  _AuctionInfoDto,
  _AuctionInfoFullDto,
} from '@/features/auction/auctionInfo.dto';
import { mapNullable } from '@/utils/mapNullable';
import { Merge } from 'type-fest';
import { UserDto } from '../../types';
import { UploadId } from '../uploads.api';

export type ShowcaseItemDto = {
  id: number;
  name: string;

  price: number | null;
  priceRetail: number | null;

  /** Additional costs for shipping added by seller to be paid by buyer */
  priceShipping: number | null;

  tradeInfo: string | null;
  damageInfo: string | null;
  tags: string[];
  itemImages: string[];
  itemVideos: string[];
  toTrade: boolean;
  toSell: boolean;
  forCashOffer: boolean;
  forCharity: boolean;
  forAuction: boolean;
  isWantedItem: boolean;
  currentAuctionId: number | null;

  /** Actual quantity that user currently possesses. */
  quantity: number;

  /** Quantity that is in trades. It is updated when a trade is accepted. */
  quantityInTrade: number;

  /** Quantity that is available for new trades. It is `quantity` - `quantityInTrade`. */
  quantityAvailable: number;

  // TODO: add reviews table with nullable text field, update ratedCount with all reviews count, update reviewsCount with non-empty reviews count
  rating: number;
  ratedCount: number;
  reviewsCount: number;

  userId: number;
  /**
   * If item was created at transfer item during trade finalize, then `tradeId`, `originatedFromItemId`, `tradedAt` are set accordingly.
   * If item was explicitly uploaded by user in his showcase, then these three fields are null.
   */
  tradeId: number | null;
  // FIXME: remove trade info from public dto
  tradedAt: string | null;
  originatedFromItemId: number | null;

  views: number;
  isInBackRoom: boolean;
  isLikedCount: number;

  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ShowcaseItemPropertyDto = {
  fieldName: string;
  parentDisplayName?: string;
  properties: Array<{
    propertyName: string;
    propertyId: number;
  }>;
  parentId?: number | null;
};

export type SoldByUserDto = Pick<
  UserDto,
  | 'id'
  | 'verificationLevel'
  | 'membershipLevel'
  | 'userName'
  | 'profilePhoto'
  | 'profilePhotoSmall'
  | 'profilePhotoMedium'
  | 'profilePhotoLarge'
>;

export type _ShowcaseItemFullDto = ShowcaseItemDto & {
  priceSellFee: number | null;

  isLiked: boolean;
  /**
   * Only available when viewing your own item. `null` if item wasn't received in a trade, `undefined` if viewing other user's item.
   */
  soldByUser?: SoldByUserDto | null;
  /**
   * Only available when viewing your own item. `null` if item wasn't received in a trade, `undefined` if viewing other user's item.
   */
  tradeOfferId?: number | null;

  itemProperties: Array<ShowcaseItemPropertyDto>;
  auctionInfo?: _AuctionInfoDto | _AuctionInfoFullDto;
};

export type ShowcaseItemFullDto = Merge<
  _ShowcaseItemFullDto,
  {
    auctionInfo?: AuctionInfoDto | AuctionInfoFullDto;
  }
>;

export function normalizeShowcaseItemFullDto(dto: _ShowcaseItemFullDto): ShowcaseItemFullDto {
  return {
    ...dto,
    auctionInfo: mapNullable((ai) => normalizeAuctionInfoDto(ai), dto.auctionInfo),
  };
}

export type _ShowcaseItemSearchDto = ShowcaseItemDto & {
  auctionInfo: _AuctionInfoDto | undefined;
  user: Pick<
    UserDto,
    | 'id'
    | 'verificationLevel'
    | 'canReceivePayment'
    | 'exhaustedTradeLimit'
    | 'membershipLevel'
    | 'userName'
  >;
};

export type ShowcaseItemSearchDto = Merge<
  _ShowcaseItemSearchDto,
  {
    auctionInfo: AuctionInfoDto | undefined;
  }
>;

export function normalizeShowcaseItemSearchDto(dto: _ShowcaseItemSearchDto): ShowcaseItemSearchDto {
  return {
    ...dto,
    auctionInfo: mapNullable((ai) => normalizeAuctionInfoDto(ai), dto.auctionInfo),
  };
}

export type _ShowcaseItemSearchDtoWithPrice = Merge<_ShowcaseItemSearchDto, { price: number }>;

export type ShowcaseItemSearchDtoWithPrice = Merge<
  _ShowcaseItemSearchDtoWithPrice,
  {
    auctionInfo: AuctionInfoDto | undefined;
  }
>;

export function normalizeShowcaseItemSearchDtoWithPrice(
  dto: _ShowcaseItemSearchDtoWithPrice,
): ShowcaseItemSearchDtoWithPrice {
  return {
    ...dto,
    auctionInfo: mapNullable((ai) => normalizeAuctionInfoDto(ai), dto.auctionInfo),
  };
}

export type ShowcaseItemCreateDto = {
  name: string;
  price: null | number;
  priceShipping: number | null;
  tradeInfo: string;
  damageInfo: string;
  toTrade: boolean;
  toSell: boolean;
  forCashOffer: boolean;
  forAuction: boolean;
  forCharity: boolean;
  auctionInfo: AuctionInfoCreateDto | null;
  quantity: number;
  itemImages: UploadId[];
  itemVideos: UploadId[];
  itemProperties: Array<{
    properties: Array<{
      propertyId: number;
    }>;
  }>;
  tags: string[];
  isWantedItem: boolean;
};

export type ShowcaseItemUpdateDto = Omit<
  Partial<ShowcaseItemCreateDto>,
  'itemImages' | 'itemVideos'
> & {
  /**
   * Indicates order of images.
   * If `number`, it is index of image inside array of new uploaded images.
   * If `string`, it is url of one of the existing images.
   * All existing images not present in this array will be deleted.
   * If this array is not present, new images are appended to the end.
   */
  itemImages?: (number | string)[];
  /**
   * Indicates order of videos.
   * If `number`, it is index of video inside array of new uploaded videos.
   * If `string`, it is url of one of the existing videos.
   * All existing videos not present in this array will be deleted.
   * If this array is not present, new videos are appended to the end.
   */
  itemVideos?: (number | string)[];
};

export type ShowCaseItemIsLikedDto = {
  isLiked: boolean;
};

export type _ShowcaseItemWithCurrentBidDto = {
  showcaseItem: _ShowcaseItemSearchDto;
  currentBid: AuctionBidDto;
};

export type ShowcaseItemWithCurrentBidDto = {
  showcaseItem: ShowcaseItemSearchDto;
  currentBid: AuctionBidDto;
};

export function normalizeShowcaseItemWithCurrentBidDto(
  dto: _ShowcaseItemWithCurrentBidDto,
): ShowcaseItemWithCurrentBidDto {
  return {
    ...dto,
    showcaseItem: mapNullable((ai) => normalizeShowcaseItemSearchDto(ai), dto.showcaseItem),
  };
}
