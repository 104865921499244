import { useEffect, useRef } from 'react';

export function useOnEnabled(onEnabled: () => void, value: boolean) {
  const ref = useRef(value);
  useEffect(() => {
    if (ref.current !== value) {
      ref.current = value;

      if (value) onEnabled();
    }
  }, [value]);
}
