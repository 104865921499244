import { TOGGLE_SEARCH_FILTERS } from '../actions/actionTypes';
import { IAction } from '../types';

interface IState {
  areSearchFiltersOpen: boolean;
  isChatBoxOpen: boolean;
}

const initialState: IState = {
  areSearchFiltersOpen: true,
  isChatBoxOpen: false,
};

const uiStateReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case TOGGLE_SEARCH_FILTERS:
      return { ...state, areSearchFiltersOpen: !state.areSearchFiltersOpen };
    default:
      return state;
  }
};

export default uiStateReducer;
