/* eslint-disable @next/next/no-img-element */
import {
  ShowcaseItemSearchDto,
  ShowcaseItemSearchDtoWithPrice,
} from '@/api/showcaseItems/showcaseItems.dto';
import { Rate } from '@/components/@next/Rate/Rate';
import CustomNextLink from '@/components/CustomNextLink';
import routes from '@/routes/routes';
import { TradeOfferItemDto } from '@/types';
import { formatCents } from '@/utils/formatCents';
import { getItemFirstImage } from '@/utils/getItemFirstImage';
import { pluralize } from '@/utils/pluralize';
import { CheckCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useAddItemToOfferDraft, useRemoveItemFromOfferDraft } from '../offerDrafts.service';
import { OfferDraftDto } from '../offerDrafts.store';
export const OfferItem = ({
  currentUserId,
  ownItems,
  item,
  itemOwnerId,
  showPriceShipping,
  forFloatingPanel,
  className,
  manageItems,
}: {
  currentUserId: number;
  ownItems: boolean;
  item: ShowcaseItemSearchDtoWithPrice | TradeOfferItemDto;
  itemOwnerId: number;
  showPriceShipping: boolean;
  forFloatingPanel?: boolean;
  className?: string;
  manageItems?: {
    offerDraft: OfferDraftDto;
    canAdd: boolean;
    canRemove: boolean;
    savedItemIds: number[];
  };
}) => {
  const isAdded = manageItems ? manageItems.savedItemIds.some((id) => id === item.id) : true;

  const addItemToOfferDraft = useAddItemToOfferDraft();
  const addItem =
    (manageItems?.canAdd &&
      !isAdded &&
      ((item: ShowcaseItemSearchDto | TradeOfferItemDto) => {
        addItemToOfferDraft({
          currentUserId,
          offerDraft: manageItems.offerDraft,
          itemId: item.id,
          isOwnItem: ownItems,
          addItemToQueryCache: 'tradeInfo' in item ? item : undefined,
        });
      })) ||
    null;

  const removeItemFromOfferDraft = useRemoveItemFromOfferDraft();
  const removeItem =
    manageItems?.canRemove &&
    ((itemId: number) => {
      removeItemFromOfferDraft({
        currentUserId,
        offerDraft: manageItems.offerDraft,
        itemId,
        isOwnItem: ownItems,
      });
    });

  return (
    <div
      key={item.id}
      className={cn(
        'group flex flex-col relative',
        className,
        forFloatingPanel ? 'gap-y-1.5' : 'gap-y-2',
      )}
    >
      <div className="pb-[100%] relative">
        {isAdded && removeItem && (
          <button
            type="button"
            className={cn(
              'duration-300 transition text-primary hover:text-primary-darker absolute top-0 right-0 z-10 bg-white rounded-full',
              !forFloatingPanel && 'hidden group-hover:block',
            )}
            onClick={() => removeItem(item.id)}
          >
            <AiFillCloseCircle className="text-xl" />
          </button>
        )}
        <div
          onClick={() => {
            addItem?.(item);
          }}
          className={cn('absolute inset-0 rounded bg-white flex-center', {
            'cursor-pointer': addItem,
          })}
        >
          <img
            className="max-w-full max-h-full"
            src={getItemFirstImage(item)}
            alt="Collectible item image"
          />
        </div>
      </div>

      {!forFloatingPanel && (
        <div>
          <CustomNextLink
            to={routes.showcaseItem({ userId: itemOwnerId, id: item.id })}
            className="w-full line-clamp-2"
          >
            {item.name}
          </CustomNextLink>
          <div className="flex items-center text-center gap-x-2 mt-1">
            <Rate disabled defaultValue={item.rating} />
            {pluralize('review', item.reviewsCount, true)}
          </div>
        </div>
      )}

      <div className="w-full flex items-start justify-between">
        {
          <div>
            <div>
              {!forFloatingPanel && <span className="text-gray-79">Price:</span>}
              <span className="text-gray-01 text-base ml-1 font-bold">
                {formatCents(item.price)}
              </span>
            </div>
            {showPriceShipping && (item.priceShipping || 0) > 0 && (
              <span className="text-gray-01 text-xs ml-1 whitespace-nowrap">
                + <b>{formatCents(item.priceShipping)}</b> shipping
              </span>
            )}
          </div>
        }
        {!forFloatingPanel && isAdded && (
          <>
            {removeItem && (
              <button className="group" onClick={() => removeItem(item.id)}>
                <div className="flex-center text-success-bright group-hover:hidden">
                  <CheckCircleFilled className="text-base mr-1" /> Added
                </div>
                <div className="flex-center text-danger hidden group-hover:flex">
                  <AiFillCloseCircle className="text-base mr-1" /> Remove
                </div>
              </button>
            )}
            {!removeItem && (
              <div className="flex-center text-success-bright">
                <CheckCircleFilled className="text-base mr-1" /> Added
              </div>
            )}
          </>
        )}
        {!forFloatingPanel && !isAdded && addItem && (
          <button type="button" className="flex-center text-gray-01" onClick={() => addItem(item)}>
            <CheckCircleFilled className="text-base mr-1" /> Add
          </button>
        )}
      </div>
    </div>
  );
};
