import { TradeOfferType } from '@/types';
import { deleteFromStorage, useReadLocalStorage, writeStorage } from '@/utils/localStorage';
import { z } from 'zod';

const storageKey = 'offerDrafts';

export const newOfferDraftKey = 'new' as const;

export const offerDraftKeyType = z.literal(newOfferDraftKey).or(z.preprocess(Number, z.number())); // preprocess string OR number to number
export type OfferDraftKey = z.infer<typeof offerDraftKeyType>;

const OfferDraftDto = z.object({
  key: offerDraftKeyType,
  type: z.nativeEnum(TradeOfferType),
  couponCode: z.string().optional(),
  funds: z
    .object({
      payerId: z.number().or(z.literal('currentUser')),
      beneficiaryId: z.number(),
      amountInCents: z.number(),
      discountInCents: z.number().nullable(),
      shippingAmountInCents: z.number(),
      taxAmountInCents: z.number(),
      transactionFeeInCents: z.number(),
      sellFeeInCents: z.number().nullable(),
      refundedInCents: z.number().nullable(),
    })
    .array(),
  itemsRequestedIds: z.array(z.number()),
  itemsOfferedIds: z.array(z.number()),
  fromUser: z.object({
    id: z.number(),
  }),
  toUser: z.object({
    id: z.number(),
  }),
  message: z.string(),
  // userId has the same meaning as in TradeOfferDto, it is set to id of user who proposed or countered a deal
  userId: z.number(),

  // set offer id after create or counter, show success modal if it is set
  submittedOfferId: z.number().optional(),
  refunded: z.number().optional(),
});

export type OfferDraftDto = z.infer<typeof OfferDraftDto>;

const AllOfferDrafts = z.record(OfferDraftDto);
type AllOfferDrafts = z.infer<typeof AllOfferDrafts>;

const getAllOfferDrafts = (): AllOfferDrafts => {
  const value = localStorage.getItem(storageKey);
  return value === null ? {} : JSON.parse(value);
};

const clearIfInvalid = () => {
  if (!process.browser) return;

  try {
    AllOfferDrafts.parse(getAllOfferDrafts());
  } catch (_) {
    deleteFromStorage(storageKey);
  }
};

export const useOfferDraftFromStore = (key: OfferDraftKey) => {
  const all = useReadLocalStorage<AllOfferDrafts>(storageKey);
  return all?.[key];
};

export const useNewOfferDraftFromStore = () => useOfferDraftFromStore(newOfferDraftKey);

export const saveOfferDraftToStore = (
  key: OfferDraftKey,
  data: OfferDraftDto | null | ((data: OfferDraftDto | null) => OfferDraftDto | null),
) => {
  const allDrafts = getAllOfferDrafts();
  const currentDraft = allDrafts[key];
  const updatedDraft = typeof data === 'function' ? data(currentDraft) : data;
  if (updatedDraft === currentDraft) return;

  const update = { ...allDrafts };
  if (updatedDraft) {
    update[key] = updatedDraft;
  } else {
    delete update[key];
  }
  writeStorage(storageKey, update);
};

export const removeOfferDraftFromStore = (key: OfferDraftKey) => {
  saveOfferDraftToStore(key, null);
};

export const saveSubmittedOfferIdToOfferDraft = (offerDraftKey: OfferDraftKey, offerId: number) => {
  saveOfferDraftToStore(
    offerDraftKey,
    (data) =>
      data && {
        ...data,
        submittedOfferId: offerId,
      },
  );
};

// end of file
// initialize
clearIfInvalid();
