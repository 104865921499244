export const ONE_SIDED_TRADE_THRESHOLD = 30;
export const MIN_CASH_OFFER_AMOUNT_IN_CENTS = 100;
export const MEDIATOR_FEE_IN_CENTS = 500;

export const SUBTRACT_PERCENT_FOR_STRIPE_FEES = parseFloat(
  process.env.NEXT_PUBLIC_SUBTRACT_PERCENT_FOR_STRIPE_FEES || '',
);
if (isNaN(SUBTRACT_PERCENT_FOR_STRIPE_FEES))
  throw new Error(`NEXT_PUBLIC_SUBTRACT_PERCENT_FOR_STRIPE_FEES must be set`);

export const DEAL_INACTIVITY_EXPIRATION_TIMEOUT_MS = parseInt(
  process.env.NEXT_PUBLIC_DEAL_INACTIVITY_EXPIRATION_TIMEOUT_MS || '',
);
if (isNaN(DEAL_INACTIVITY_EXPIRATION_TIMEOUT_MS))
  throw new Error(`NEXT_PUBLIC_DEAL_INACTIVITY_EXPIRATION_TIMEOUT_MS must be set`);

export const INSTANT_PURCHASES_ENABLED =
  process.env.NEXT_PUBLIC_INSTANT_PURCHASES_ENABLED === 'true';

export const TRADE_MESSAGING_ENABLED = process.env.NEXT_PUBLIC_TRADE_MESSAGING_ENABLED === 'true';
