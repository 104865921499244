export enum TradeDeliveryStatus {
  PROPOSED = 'proposed',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  COUNTERED = 'countered',
  CANCELED = 'canceled',
}

export enum TradeDeliveryType {
  SAFE_LOCATION = 'safe-trade-location',
  SHIPPING = 'shipping',
  MEDIATOR = 'mediator',
}

export interface TradeDeliveryCommonDto {
  id: number;
  tradeId: number;

  status: TradeDeliveryStatus;
  type: TradeDeliveryType;

  /** Author of the trade delivery offer. */
  userId: number;
}

export type TradeDeliveryShippingDetailsDto = {
  address: string;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  email: string;
};

export type TradeDeliveryShippingDto = TradeDeliveryCommonDto & {
  type: TradeDeliveryType.SHIPPING;
  fromUserDetails: null | TradeDeliveryShippingDetailsDto;
  toUserDetails: null | TradeDeliveryShippingDetailsDto;
};

export type TradeDeliveryDto = TradeDeliveryShippingDto;

export type ShippoAddressesDto = {
  city: string;
  state: string;
  zip: string;
  country: string;
};

export type ShippoStatusDto = {
  status_date: string;
  status_details: string;
  location: ShippoAddressesDto;
  substatus: string | null;
  object_created: string;
  object_updated: string | null;
  object_id: string;
  status: string;
};

export type ShippoMessageDto = {
  source: string | null | undefined;
  code: string | null | undefined;
  type: string | null | undefined;
  text: string;
};

export type ShippoTrackingDto = {
  tracking_number: string;
  carrier: string;
  servicelevel: {
    name: string | null;
    token: string | null;
  };
  transaction: string | null;
  address_from: ShippoAddressesDto | null;
  address_to: ShippoAddressesDto | null;
  eta: string;
  original_eta: string;
  metadata: string | null;
  test: boolean;
  tracking_status: ShippoStatusDto;
  tracking_history: ShippoStatusDto[];
  messages: ShippoMessageDto[];
};
