import { useShowcaseItemsQuery } from '@/api/showcaseItems/showcaseItems.api';
import {
  ShowcaseItemDto,
  ShowcaseItemSearchDto,
  ShowcaseItemSearchDtoWithPrice,
} from '@/api/showcaseItems/showcaseItems.dto';
import routes from '@/routes/routes';
import { TradeOfferDto, TradeOfferType } from '@/types';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  newOfferDraftKey,
  OfferDraftDto,
  OfferDraftKey,
  removeOfferDraftFromStore,
  saveOfferDraftToStore,
} from './offerDrafts.store';
import { getIsWantedItemOffer, pickItems } from './tradeOffer.utils';

export const saveNewOfferDraft = ({
  item,
  type,
  currentUserId,
}: {
  item: ShowcaseItemDto;
  type: TradeOfferType;
  currentUserId: number;
}) => {
  saveOfferDraftToStore(newOfferDraftKey, {
    key: newOfferDraftKey,
    type,
    funds: [],
    itemsRequestedIds: [item.id],
    itemsOfferedIds: [],
    fromUser: {
      id: currentUserId,
    },
    toUser: {
      id: item.userId,
    },
    message: '',
    userId: currentUserId,
  });
};

export const saveOfferDraftForCounterOffer = ({ offer }: { offer: TradeOfferDto }) => {
  saveOfferDraftToStore(offer.id, {
    key: offer.id,
    type: offer.type,
    funds: offer.funds,
    itemsRequestedIds: offer.itemsRequested?.map((item) => item.id) || [],
    itemsOfferedIds: offer.itemsOffered?.map((item) => item.id) || [],
    fromUser: {
      id: offer.fromUser.id,
    },
    toUser: {
      id: offer.toUser.id,
    },
    message: offer.message,
    userId: offer.userId,
  });
};

const offerDraftItemsQueryKey = (offer: OfferDraftDto) => ['offerDraftItems', offer.key];

const useUpdateOfferDraftItemsQueryCache = () => {
  const queryClient = useQueryClient();

  return (
    offer: OfferDraftDto,
    updater: (data: ShowcaseItemSearchDto[]) => ShowcaseItemSearchDto[] | undefined,
  ) => {
    queryClient.setQueryData<ShowcaseItemSearchDto[] | undefined>(
      offerDraftItemsQueryKey(offer),
      (data) => {
        return data && updater(data);
      },
    );
  };
};

export const useAddItemToOfferDraft = () => {
  const updateOfferDraftItemsQueryCache = useUpdateOfferDraftItemsQueryCache();

  return ({
    offerDraft,
    currentUserId,
    itemId,
    addItemToQueryCache,
    isOwnItem,
  }: {
    offerDraft: OfferDraftDto;
    currentUserId: number;
    itemId: number;
    addItemToQueryCache?: ShowcaseItemSearchDto;
    isOwnItem: boolean;
  }) => {
    const updated = { ...offerDraft };
    const idsArrayName = pickItems({
      ownItems: isOwnItem,
      currentUserId,
      offer: offerDraft,
      requested: 'itemsRequestedIds' as const,
      offered: 'itemsOfferedIds' as const,
    });
    if (!getIsWantedItemOffer(offerDraft) || offerDraft.itemsOfferedIds.length == 0) {
      updated[idsArrayName] = [...updated[idsArrayName], itemId];
      saveOfferDraftToStore(offerDraft.key, updated);
      if (addItemToQueryCache) {
        updateOfferDraftItemsQueryCache(offerDraft, (data) => [...data, addItemToQueryCache]);
      }
    }
  };
};

export const useRemoveItemFromOfferDraft = () => {
  const updateOfferDraftItemsQueryCache = useUpdateOfferDraftItemsQueryCache();

  return ({
    currentUserId,
    offerDraft,
    itemId,
    isOwnItem,
  }: {
    currentUserId: number;
    offerDraft: OfferDraftDto;
    itemId: number;
    isOwnItem: boolean;
  }) => {
    const updated = { ...offerDraft };
    const idsArrayName = pickItems({
      ownItems: isOwnItem,
      currentUserId,
      offer: offerDraft,
      requested: 'itemsRequestedIds' as const,
      offered: 'itemsOfferedIds' as const,
    });
    updated[idsArrayName] = updated[idsArrayName].filter((savedId) => savedId !== itemId);
    saveOfferDraftToStore(offerDraft.key, updated);
    updateOfferDraftItemsQueryCache(offerDraft, (data) =>
      data.filter((item) => item.id !== itemId),
    );
  };
};

export type OfferDraftItemsQuery = ReturnType<typeof useOfferDraftItemsQuery>;

export const useOfferDraftItemsQuery = (
  offer: OfferDraftDto,
): {
  isLoading: boolean;
  data?: {
    itemsRequested: ShowcaseItemSearchDtoWithPrice[];
    itemsOffered: ShowcaseItemSearchDtoWithPrice[];
  };
} => {
  const updateOfferDraftItemsQueryCache = useUpdateOfferDraftItemsQueryCache();

  const allIds = [...offer.itemsRequestedIds, ...offer.itemsOfferedIds];

  const { data: rawData, isLoading } = useShowcaseItemsQuery(
    {
      ids: allIds,
    },
    {
      queryKey: offerDraftItemsQueryKey(offer),
    },
  );

  const data = useMemo(() => {
    if (!rawData) return rawData;

    const itemsRequested: ShowcaseItemSearchDtoWithPrice[] = [];
    const itemsOffered: ShowcaseItemSearchDtoWithPrice[] = [];
    const foundIds: Record<number, true> = {};

    rawData.forEach((item) => {
      let { price, isWantedItem } = item;
      if (isWantedItem) price = 0;
      else {
        if (!price) return;
      }

      foundIds[item.id] = true;

      if (offer.itemsRequestedIds.includes(item.id)) {
        itemsRequested.push({ ...item, price });
      } else if (offer.itemsOfferedIds.includes(item.id)) {
        itemsOffered.push({ ...item, price });
      }
    });

    return {
      itemsRequested,
      itemsOffered,
      missingIds: allIds.filter((id) => !foundIds[id]),
    };
  }, [rawData]);

  useEffect(() => {
    if (!data) return;

    const { missingIds } = data;
    if (!missingIds.length) return;

    updateOfferDraftItemsQueryCache(offer, (data) =>
      data.filter((item) => !missingIds.includes(item.id)),
    );

    saveOfferDraftToStore(
      offer.key,
      (draft) =>
        draft && {
          ...draft,
          itemsRequestedIds: draft.itemsRequestedIds.filter((id) => !missingIds.includes(id)),
          itemsOfferedIds: draft.itemsOfferedIds.filter((id) => !missingIds.includes(id)),
        },
    );
  }, [data]);

  return {
    isLoading,
    data,
  };
};

export const setCouponCodeToOfferDraft = (
  offerDraftKey: OfferDraftKey,
  couponCode: string | undefined,
) => {
  saveOfferDraftToStore(offerDraftKey, (data) => data && { ...data, couponCode });
};

export const setFundsToOfferDraft = (
  offerDraftKey: OfferDraftKey,
  funds: OfferDraftDto['funds'],
) => {
  saveOfferDraftToStore(offerDraftKey, (data) => data && { ...data, funds });
};

export const updateFundsToOfferDraft = (
  offerDraftKey: OfferDraftKey,
  updateFunds: (funds: OfferDraftDto['funds']) => OfferDraftDto['funds'],
) => {
  saveOfferDraftToStore(
    offerDraftKey,
    (data) => data && { ...data, funds: updateFunds(data.funds) },
  );
};

export const setMessageToOfferDraft = (offerDraft: OfferDraftDto, message: string) => {
  saveOfferDraftToStore(offerDraft.key, (data) => data && { ...data, message });
};

export const useCancelOffer = (offerDraft: OfferDraftDto) => {
  const router = useRouter();
  return () => {
    router.push(routes.home).then(() => {
      removeOfferDraftFromStore(offerDraft.key);
    });
  };
};
