import CustomTooltip from '@/features/auth/components/CustomTooltip';
import * as Select from '@radix-ui/react-select';
import cn from 'classnames';
import { ReactElement, ReactNode, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { BiErrorCircle } from 'react-icons/bi';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import styles from './customSelect.module.scss';

export type CustomSelectProps = {
  onChange?: (value: string | undefined) => void;
  values: { label: string; value: string }[];
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  selectClassName?: string;
  labelClassName?: string;
  caretIcon?: ReactElement;
  label?: ReactNode;
  registerFn?: UseFormRegisterReturn;
  error?: FieldError | undefined;
  toolTipMessage?: string | undefined;
};

export const CustomRadixSelect = ({
  onChange,
  values,
  placeholder,
  className,
  selectClassName,
  labelClassName,
  defaultValue,
  caretIcon,
  label,
  registerFn,
  error,
  toolTipMessage,
}: CustomSelectProps) => {
  const [val, setVal] = useState<string | undefined>(defaultValue ? defaultValue : undefined);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  return (
    <div className={label ? cn('flex flex-col items-start', className) : className}>
      {label && (
        <span className={cn('text-secondary text-base font-bold mb-3', labelClassName)}>
          {label}
        </span>
      )}
      <Select.Root
        {...registerFn}
        onValueChange={(value) => {
          setVal(value);
          if (onChange) onChange(value);
        }}
        value={val}
      >
        <Select.Trigger className={cn(styles['select-trigger'], selectClassName)}>
          <Select.Value placeholder={placeholder} />
          <Select.Icon className={styles['select-icon']}>
            {!!caretIcon ? caretIcon : <BsFillCaretDownFill />}
          </Select.Icon>
          <div className={`absolute right-10 top-4`}>
            <BiErrorCircle
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
              className={`${error ? 'flex' : 'hidden'} w-5 h-5 text-danger-darker`}
            />
            {showToolTip && <CustomTooltip message={toolTipMessage} />}
          </div>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content className={styles['select-content']}>
            <Select.ScrollUpButton className={styles['select-scroll-button']}>
              <BsFillCaretUpFill />
            </Select.ScrollUpButton>
            <Select.Viewport className={styles['select-viewport']}>
              {values.map((value) => (
                <Select.Item
                  className={styles['select-item']}
                  key={value.value}
                  value={value.value}
                >
                  <Select.ItemText>{value.label}</Select.ItemText>
                </Select.Item>
              ))}
            </Select.Viewport>
            <Select.ScrollDownButton className={styles['select-scroll-button']}>
              <BsFillCaretDownFill />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
};
