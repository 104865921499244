import { useCurrentUserId } from '@/features/user/currentUser.service';
import Link from 'next/link';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import routes from '../../../../../routes/routes';
import { UserFullDto } from '../../../../../types';
import StandaloneLoader from '../../../StandaloneLoader';
import Search from '../Search/Search';
import { siteLinkClassNames } from '../styles';
import { UserDropdown } from '../UserMenu/UserDropdown';

const SmallScreenActionBar = ({
  currentUser,
  isUserLoading,
}: {
  currentUser: UserFullDto | undefined;
  isUserLoading: boolean;
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const currentUserId = useCurrentUserId();

  return (
    <div className="grow md:hidden flex justify-end">
      {isUserLoading ? (
        <StandaloneLoader />
      ) : (
        <div className="flex justify-end items-center grow gap-x-4">
          <BiSearch
            size={24}
            className={`${siteLinkClassNames} ${
              isSearchOpen ? 'text-primary' : currentUser ? 'text-gray-01' : 'text-gray-79'
            }`}
            onClick={() => setIsSearchOpen((prev) => !prev)}
          />

          {currentUser ? (
            <UserDropdown currentUser={currentUser} />
          ) : (
            <>
              <Link href={routes.login()}>
                <a className={`${siteLinkClassNames} text-gray-79`}>Login</a>
              </Link>
              <Link href={routes.signUp()}>
                <a className="btn-new btn-primary">Get Started</a>
              </Link>
            </>
          )}
        </div>
      )}

      {isSearchOpen && (
        <div className="absolute bg-white top-full z-20 w-[calc(100%+2px)] -left-[1px] border border-gray-d9 border-t-0 px-2 py-4">
          <Search />
        </div>
      )}
    </div>
  );
};

export default SmallScreenActionBar;
