import { useTradeCountQuery } from '@/api/tradeOffer/tradeOffer.api';
import { getCanLiveStream } from '@/features/liveStream/liveStream.service';
import { UserFullDto } from '@/types';
import { Badge } from 'antd';
import Link from 'next/link';
import { useState } from 'react';
import routes from '../../../../../routes/routes';

const SiteLinks = ({
  linksClassNames,
  currentUser,
}: {
  linksClassNames: string;
  currentUser: UserFullDto | undefined;
}) => {
  const canLiveStream = currentUser ? getCanLiveStream({ user: currentUser }) : false;

  const currentUserId = currentUser?.id;
  const [tradeCountValue, setTradeCartValue] = useState<number>(0);
  useTradeCountQuery({
    enabled: !!currentUserId,
    onSuccess: (data) => {
      const count = Object.values(data || {}).reduce((acc, v) => acc + v, 0);
      setTradeCartValue(count);
    },
  });

  return (
    <>
      <div className="flex gap-x-5">
        <Link href={routes.auctions.list}>
          <a className={`${linksClassNames}`}>Live Auctions</a>
        </Link>
        <Link href={routes.auctions.charity.list}>
          <a className={`${linksClassNames}`}>Charities</a>
        </Link>
        {!currentUserId && (
          <Link href={routes.showcases}>
            <a className={`${linksClassNames}`}>Showcases</a>
          </Link>
        )}
      </div>
      {currentUserId && (
        <>
          <Link href={routes.showcase({ userId: currentUserId })}>
            <a className={`${linksClassNames}`}>Showcase</a>
          </Link>
          <Link href={routes.currentBids()}>
            <a className={`${linksClassNames}`}>Current Bids</a>
          </Link>
          <Badge count={tradeCountValue} color="#2176ff">
            <Link href={routes.tradeOffers()}>
              <a className={`${linksClassNames}`}>Offers</a>
            </Link>
          </Badge>
        </>
      )}
    </>
  );
};

export default SiteLinks;
