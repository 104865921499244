import { MembershipLevelName } from './membership.api';

export type MembershipCapabilities = {
  canHaveStripeAccount: boolean;
  canBuy: boolean;
  canTrade: boolean;
  canSell: boolean;
  canRequestFunds: boolean;
  canAcceptFollowers: boolean;
  canEbayImport: boolean;
  canCustomWebsite: boolean;
  isBuyerOnly: boolean;
  isCharity: boolean; // TODO: take it from db?
  isWholesaler: boolean;
  auctionFeature: boolean;
  liveStream: boolean;
  canSkipFirstLastName: boolean;
};

const membershipCapabilities: Record<MembershipLevelName, MembershipCapabilities> = {
  Pro: {
    canHaveStripeAccount: true,
    canBuy: true,
    canTrade: true,
    canSell: true,
    canRequestFunds: true,
    canAcceptFollowers: false,
    canEbayImport: false,
    canCustomWebsite: false,
    isBuyerOnly: false,
    isCharity: false,
    isWholesaler: false,
    auctionFeature: false,
    liveStream: false,
    canSkipFirstLastName: false,
  },
  VIP: {
    canHaveStripeAccount: true,
    canBuy: true,
    canTrade: true,
    canSell: true,
    canRequestFunds: true,
    canAcceptFollowers: true,
    canEbayImport: true,
    canCustomWebsite: true,
    isBuyerOnly: false,
    isCharity: false,
    isWholesaler: false,
    auctionFeature: true,
    liveStream: true,
    canSkipFirstLastName: false,
  },
  Buyer: {
    canHaveStripeAccount: false,
    canBuy: true,
    canTrade: true,
    canSell: false,
    canRequestFunds: false,
    canAcceptFollowers: false,
    canEbayImport: false,
    canCustomWebsite: false,
    isBuyerOnly: true,
    isCharity: false,
    isWholesaler: false,
    auctionFeature: false,
    liveStream: false,
    canSkipFirstLastName: false,
  },
  Charity: {
    canHaveStripeAccount: true,
    canBuy: true,
    canTrade: true,
    canSell: true,
    canRequestFunds: true,
    canAcceptFollowers: true,
    canEbayImport: true,
    canCustomWebsite: true,
    isBuyerOnly: false,
    isCharity: true,
    isWholesaler: false,
    auctionFeature: true,
    liveStream: true,
    canSkipFirstLastName: true,
  },
  Wholesaler: {
    canHaveStripeAccount: true,
    canBuy: true,
    canTrade: true,
    canSell: true,
    canRequestFunds: true,
    canAcceptFollowers: true,
    canEbayImport: true,
    canCustomWebsite: true,
    isBuyerOnly: false,
    isCharity: false,
    isWholesaler: true,
    auctionFeature: true,
    liveStream: true,
    canSkipFirstLastName: false,
  },
};

export function getMembershipCapabilities(
  membershipLevel: MembershipLevelName | null | undefined,
): MembershipCapabilities {
  if (membershipLevel == null) {
    return {
      canHaveStripeAccount: false,
      canBuy: false,
      canTrade: false,
      canSell: false,
      canRequestFunds: false,
      canAcceptFollowers: false,
      canEbayImport: false,
      canCustomWebsite: false,
      isBuyerOnly: false,
      isCharity: false,
      isWholesaler: false,
      auctionFeature: false,
      liveStream: false,
      canSkipFirstLastName: false,
    };
  }
  return membershipCapabilities[membershipLevel];
}
