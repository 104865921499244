import { useOptionalCurrentUserDataQuery } from '@/api/user/user.api';
import CustomNextLink from '@/components/CustomNextLink';
import { useIsMobile } from '@/hooks/useIsMobile';
import routes from '@/routes/routes';
import Link from 'next/link';
import StandaloneLoader from '../../StandaloneLoader';
import CategoryLinks from './NavBarLinks/CategoryLinks';
import SiteLinks from './NavBarLinks/SiteLinks';
import Search from './Search/Search';
import SmallScreenActionBar from './SmallScreenActionBar/SmallScreenActionBar';
import { siteLinkClassNames } from './styles';
import UserMenu from './UserMenu/UserMenu';

const MainHeader = () => {
  const isMobile = useIsMobile();
  const { data: currentUser, isLoading: isUserLoading } = useOptionalCurrentUserDataQuery();
  return (
    <div
      className={`bg-white flex items-center p-2 md:p-5 gap-x-7 border border-gray-d9 ${
        isMobile ? 'fixed right-0 left-0 top-0 z-[1000] h-[68px]' : 'relative'
      }`}
    >
      <CustomNextLink to={routes.home} className="w-[43px] md:w-[86px] h-[43px] md:h-[86px]">
        <img className="w-full h-full" src="/images/new-header-logo.svg" alt="logo" />
      </CustomNextLink>

      <SmallScreenActionBar currentUser={currentUser} isUserLoading={isUserLoading} />

      <div className="hidden md:flex flex-col gap-y-5 grow">
        <div className="flex flex-col-reverse items-end lg:flex-row justify-between lg:items-center gap-y-3 gap-x-5">
          <Search />

          {isUserLoading ? (
            <StandaloneLoader />
          ) : (
            <div className="flex items-center gap-x-5">
              <SiteLinks
                linksClassNames={`${siteLinkClassNames} ${
                  currentUser ? 'text-gray-01' : 'text-gray-79'
                }`}
                currentUser={currentUser}
              />
              {currentUser ? (
                <UserMenu currentUser={currentUser} />
              ) : (
                <>
                  <Link href={routes.login()}>
                    <a className={`${siteLinkClassNames} text-gray-79`}>Login</a>
                  </Link>
                  <Link href={routes.signUp()}>
                    <a className="btn-new btn-primary">Get Started</a>
                  </Link>
                </>
              )}
            </div>
          )}
        </div>

        <div className="slef-start flex gap-x-8 items-center">
          <CategoryLinks />
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
