import { Merge } from 'type-fest';
import { z } from 'zod';
import { TradeOfferItemDto, TradeOfferType } from '../../types';
import {
  buildApiMutationHook,
  buildApiQueryHook,
  buildApiQueryNoParamsHook,
  buildApiQueryNoParamsWithTransformHook,
  buildApiQueryWithTransformHook,
} from '../../utils/api';
import { queryClient } from '../../utils/queryClient';
import axiosI from '../base.api';
import {
  normalizeShowcaseItemFullDto,
  normalizeShowcaseItemSearchDto,
  normalizeShowcaseItemSearchDtoWithPrice,
  ShowcaseItemCreateDto,
  ShowcaseItemDto,
  ShowcaseItemFullDto,
  ShowCaseItemIsLikedDto,
  ShowcaseItemPropertyDto,
  ShowcaseItemSearchDto,
  ShowcaseItemSearchDtoWithPrice,
  ShowcaseItemUpdateDto,
  _ShowcaseItemFullDto,
  _ShowcaseItemSearchDto,
  _ShowcaseItemSearchDtoWithPrice,
} from './showcaseItems.dto';

export type OrderByValue = ShowcaseItemsAPIParams['orderBy'];
export type OrderByOption = { value: OrderByValue; title: string };

const getShowcaseItemQueryKey = {
  all: () => 'showcaseItem',
  hot: () => ['showcaseItem', 'hot'],
  byId: (id: number) => ['showcaseItem', id],
  byIdWithRawPrice: ({ id, rawPrice }: { id: number; rawPrice?: boolean }) => [
    'showcaseItem',
    id,
    rawPrice,
  ],
  byUserId: (userId: number) => ['showcaseItem', 'byUserId', userId],
  soldItemsByUserId: (userId: number) => ['showcaseItem', 'byUserId', userId, 'soldItems'],
};

export const invalidateShowcaseItemQueries = {
  all: () => queryClient.invalidateQueries(getShowcaseItemQueryKey.all()),
  byId: (id: number) => queryClient.invalidateQueries(getShowcaseItemQueryKey.byId(id)),
  byIdWithRawPrice: (args: { id: number; rawPrice?: boolean }) =>
    queryClient.invalidateQueries(getShowcaseItemQueryKey.byIdWithRawPrice(args)),
  byUserId: (userId: number) =>
    queryClient.invalidateQueries(getShowcaseItemQueryKey.byUserId(userId)),
  soldItemsByUserId: (userId: number) =>
    queryClient.invalidateQueries(getShowcaseItemQueryKey.soldItemsByUserId(userId)),
};

export const ShowcaseItemsOrderByEnum = z.enum([
  'itemsCount',
  'newest',
  'rating',
  'priceAsc',
  'priceDesc',
  'tradedAt',
]);
export type ShowcaseItemsOrderByEnum = z.infer<typeof ShowcaseItemsOrderByEnum>;

export const AuctionTypeEnum = z.enum(['regular', 'fantasy', 'charity']);
export type AuctionTypeEnum = z.infer<typeof AuctionTypeEnum>;

type ShowcaseItemsBaseAPIParams = {
  sportId?: number;
  itemTypeId?: number;
  itemTypeAttributeId?: number;
  userId?: number;
  limit?: number;
  offset?: number;
  orderBy?: ShowcaseItemsOrderByEnum;
  search?: string;
  secureMembers?: boolean;
  sameCity?: boolean;
  toTrade?: boolean;
  toSell?: boolean;
  forCashOffer?: boolean;
  forAuction?: boolean;
  forCharity?: boolean;
  mySaves?: boolean;
  isWantedItem?: boolean;
  isAddingToLiveAuction?: boolean;
  auctionType?: AuctionTypeEnum;
  isAuctionClosed?: boolean;
  excludeOutOfStock?: boolean;
};

type ShowcaseItemsSearchApiParams = ShowcaseItemsBaseAPIParams & {
  search: string;
};

type ShowcaseItemsNoSearchApiParams = ShowcaseItemsBaseAPIParams & {
  ids?: number[];
};

export type ShowcaseItemsAPIParams = ShowcaseItemsSearchApiParams | ShowcaseItemsNoSearchApiParams;

export const useShowcaseItemsQuery = buildApiQueryWithTransformHook<
  ShowcaseItemsAPIParams,
  _ShowcaseItemSearchDto[],
  ShowcaseItemSearchDto[]
>(
  getShowcaseItemQueryKey.all(),
  (params) => axiosI.get('/showcaseItems', { params }),
  (raw) => raw.map((x) => normalizeShowcaseItemSearchDto(x)),
);

export const useShowcaseItemsHotQuery = buildApiQueryNoParamsWithTransformHook<
  _ShowcaseItemSearchDto[],
  ShowcaseItemSearchDto[]
>(
  getShowcaseItemQueryKey.hot(),
  () => axiosI.get('/showcaseItems/hot'),
  (raw) => raw.map((x) => normalizeShowcaseItemSearchDto(x)),
);

export type TradeQueryParams = { toTrade: true } | { toSell: true } | { forCashOffer: true } | {};

export const tradeTypeToQueryParams = (type: TradeOfferType) =>
  ({
    toTrade: type === TradeOfferType.TRADE || undefined,
    toSell: type === TradeOfferType.BUY || undefined,
    forCashOffer: type === TradeOfferType.CASH_OFFER || undefined,
  } as TradeQueryParams);

export const useShowcaseItemsWithPriceQuery = buildApiQueryWithTransformHook<
  Merge<ShowcaseItemsAPIParams, TradeQueryParams>,
  _ShowcaseItemSearchDtoWithPrice[],
  ShowcaseItemSearchDtoWithPrice[]
>(
  getShowcaseItemQueryKey.all(),
  (params) => axiosI.get('/showcaseItems', { params }),
  (raw) => raw.map((x) => normalizeShowcaseItemSearchDtoWithPrice(x)),
);

export const useShowcaseItemQuery = buildApiQueryWithTransformHook<
  { id: number; rawPrice?: boolean },
  _ShowcaseItemFullDto,
  ShowcaseItemFullDto
>(
  getShowcaseItemQueryKey.byIdWithRawPrice,
  ({ id, rawPrice }) => axiosI.get(`/showcaseItems/get/${id}`, { params: { rawPrice } }),
  (raw) => normalizeShowcaseItemFullDto(raw),
);

export const useShowcaseItemsSoldByUserIdQuery = buildApiQueryHook<
  { userId: number },
  TradeOfferItemDto[]
>(
  ({ userId }) => getShowcaseItemQueryKey.soldItemsByUserId(userId),
  ({ userId }) => axiosI.get(`/showcaseItems/getSoldItemsById/${userId}`),
);

export type ShowcaseLikeAPIParams = {
  id: number;
};

export const useLikeShowcaseItemMutation = buildApiMutationHook<
  ShowcaseLikeAPIParams,
  ShowCaseItemIsLikedDto
>(
  (data) => {
    return axiosI.post(`/users/likedItems/${data.id}`);
  },
  (options) => ({
    ...options,
    onSuccess(...args) {
      // only invalidating a particular item because refetching everything is too much
      invalidateShowcaseItemQueries.byId(args[1].id);
      options?.onSuccess?.(...args);
    },
  }),
);

export const useUnlikeShowcaseItemMutation = buildApiMutationHook<
  ShowcaseLikeAPIParams,
  ShowCaseItemIsLikedDto
>(
  (data) => {
    return axiosI.delete(`/users/likedItems/${data.id}`);
  },
  (options) => ({
    ...options,
    onSuccess(...args) {
      // only invalidating a particular item because refetching everything is too much
      invalidateShowcaseItemQueries.byId(args[1].id);
      options?.onSuccess?.(...args);
    },
  }),
);

export type CanAddShowcaseItemResult =
  | { canAddShowcaseItem: true }
  | {
      canAddShowcaseItem: false;
      reason:
        | 'not-logged-in'
        | 'exceeded-item-limit'
        | 'no-membership-level'
        | 'insufficient-membership-level'
        | 'connected-account-required'
        | 'connected-account-failure'
        | 'level1-verification-failure';
    };

export const useCanAddShowcaseItem = buildApiQueryNoParamsHook<CanAddShowcaseItemResult>(
  'canAddShowcaseItem',
  () => axiosI.get('/users/can-add-showcase-item'),
);

type CanParticipateInNewTradeResult =
  | { canParticipateInNewTrade: true }
  | {
      canParticipateInNewTrade: false;
      reason: 'insufficient-membership-level';
    };

export const useCanParticipateInNewTradeQuery =
  buildApiQueryNoParamsHook<CanParticipateInNewTradeResult>('canParticipateInNewTrade', () =>
    axiosI.get('/users/can-participate-in-new-trade'),
  );

export const useShowcaseItemsCount = buildApiQueryHook<ShowcaseItemsAPIParams, number>(
  'showcaseItemsCount',
  (params) => axiosI.get('/showcaseItems/count', { params }),
);

type ShowcaseItemsAddParams = ShowcaseItemCreateDto;
type ShowcaseItemsAddResult = ShowcaseItemDto;

export const useShowcaseItemsAdd = buildApiMutationHook<
  ShowcaseItemsAddParams,
  ShowcaseItemsAddResult
>(
  (params) => axiosI.post('/showcaseItems', params),
  (options) => ({
    ...options,
    onSuccess(...args) {
      invalidateShowcaseItemQueries.all();
      options?.onSuccess?.(...args);
    },
  }),
);

type ShowcaseItemsEditParams = {
  showcaseItemId: number;
  data: ShowcaseItemUpdateDto;
};

export const useShowcaseItemsEdit = buildApiMutationHook<ShowcaseItemsEditParams>(
  ({ showcaseItemId, data }) => axiosI.patch(`/showcaseItems/${showcaseItemId}`, data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(getShowcaseItemQueryKey.all());
      options?.onSuccess?.(...args);
    },
  }),
);

export const getShowcaseItemPropertiesByIds = buildApiMutationHook<
  {
    itemProperties: Array<{
      properties: Array<{
        propertyId: number;
      }>;
    }>;
  },
  ShowcaseItemPropertyDto[]
>(
  (data) => axiosI.post(`/showcaseItemPropertiesByIds`, data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(getShowcaseItemQueryKey.all());
      options?.onSuccess?.(...args);
    },
  }),
);

type ShowcaseItemsReviewParams = {
  showcaseItemId: number;
  reviewRating: number;
};
type ShowcaseItemsReviewResult = undefined;

export const useShowcaseItemsReview = buildApiMutationHook<
  ShowcaseItemsReviewParams,
  ShowcaseItemsReviewResult
>(
  ({ showcaseItemId, reviewRating }) =>
    axiosI.post(`/review/showcase-item/${showcaseItemId}`, { reviewRating }),
  (options) => ({
    ...options,
    onSuccess(...args) {
      // only invalidating a particular item because refetching everything is too much
      queryClient.invalidateQueries(getShowcaseItemQueryKey.byId(args[1].showcaseItemId));
      options?.onSuccess?.(...args);
    },
  }),
);

export const useDeleteShowcaseItem = buildApiMutationHook<{ showcaseItemId: number }>(
  ({ showcaseItemId }) => axiosI.delete(`/showcaseItems/${showcaseItemId}`),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(getShowcaseItemQueryKey.all());
      options?.onSuccess?.(...args);
    },
  }),
);

export const useBackRoomItemsQuery = buildApiQueryNoParamsWithTransformHook<
  _ShowcaseItemSearchDto[],
  ShowcaseItemSearchDto[]
>(
  'backRoomItems',
  () => axiosI.get(`/showcaseItems/backroom`),
  (raw) => raw.map((x) => normalizeShowcaseItemSearchDto(x)),
);

export const useTransferBackRoomItem = buildApiMutationHook<{ showcaseItemId: number }>(
  ({ showcaseItemId }) => axiosI.post(`/showcaseItems/backroom/transfer/${showcaseItemId}`),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(getShowcaseItemQueryKey.all());
      options?.onSuccess?.(...args);
    },
  }),
);
