import { useUnreadNotificationsCountQuery } from '@/api/notification/notification.api';
import { logout } from '@/features/auth/auth.service';
import { useIsAuthenticated } from '@/features/user/currentUser.service';
import { formatUserPersonalName } from '@/features/user/user.service';
import { useApiWebSocket } from '@/hooks/useApiWebSocket';
import routes from '@/routes/routes';
import { UserFullDto } from '@/types';
import { getProfilePhoto } from '@/utils/profilePhoto';
import { CaretDownFilled } from '@ant-design/icons';
import { Badge } from 'antd';
import Link from 'next/link';
import { FaSignOutAlt } from 'react-icons/fa';
import { TbBell } from 'react-icons/tb';
import { siteLinkClassNames } from '../styles';

const UserMenu = ({ currentUser }: { currentUser: UserFullDto }) => {
  // HACK: Somehow on logout this component gets rerendered with old props despite
  // the fact that `currentUser` is already `undefined` in the parent component
  // and this component shouldn't be rendered AT ALL.
  // This causes the query to fire which then fails with an error because it's not
  // supposed to be fired for anonymous users. Hence here we are directly using
  // `useIsAuthenticated` that gets data from local storage because props are not
  // up-to-date.
  const isAuthenticated = useIsAuthenticated();
  const unreadNotificationsCount = useUnreadNotificationsCountQuery({
    enabled: isAuthenticated,
  });

  useApiWebSocket({
    onMessage: (message) => {
      if (message.type !== 'notification') return;

      unreadNotificationsCount.refetch();
    },
  });

  return (
    <>
      <Link href={routes.notifications()}>
        <a className={`${siteLinkClassNames} text-gray-01 relative flex items-center`}>
          <Badge count={unreadNotificationsCount.data?.count ?? 0} color="#2176ff">
            <TbBell size={24} />
          </Badge>

          <CaretDownFilled className="text-sm text-gray-79" />
        </a>
      </Link>
      <span className="cursor-pointer" onClick={logout}>
        <FaSignOutAlt size={24} />
      </span>

      <div className="flex items-center gap-x-3">
        <img
          className="w-12 h-12 rounded-full"
          src={getProfilePhoto(currentUser, 'original')}
          alt="profile-picture"
        />

        <div className="flex flex-col items-start">
          <span className="text-base font-bold capitalize max-w-[110px] truncate">
            {formatUserPersonalName(currentUser)}
          </span>
          <Link href={routes.user()}>
            <a>
              <span className="text-blue-21 border-b border-blue-21">My Profile</span>
            </a>
          </Link>
        </div>
      </div>
    </>
  );
};

export default UserMenu;
