import { INSTANT_PURCHASES_ENABLED } from '@/features/trade/offer.constants';
import { Stripe } from 'stripe';
import { ChatMessageWebSocketMessage } from './api/chat/chat.dto';
import type { NotificationWebSocketMessage } from './api/notification/notification.dto';
import { TradeDeliveryDto } from './api/trade/tradeDelivery/tradeDelivery.dto';
import { TradeDeliveryReceiveDto, TradeOfferReplyDto } from './api/tradeOffer/tradeOffer.dto';
import { ProfilePhotoMetadata } from './api/user/user.dto';
import { LiveStreamWebSocketMessage } from './features/liveStream/liveStream.message';
import type { MembershipLevelName } from './features/membership/membership.api';
import { assert } from './utils/assert';
import { ImageMimeType } from './utils/image';
export interface IAction {
  type: string;
  payload?: any;
}

export interface IUserData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isBuyer?: boolean;
}

export type UserVerificationLevelEnum =
  | 'not-verified'
  | 'contact-info-verified'
  | 'identity-verified';

export type UserDto = {
  id: number;
  userName: string;
  rating: number;
  membershipLevel: MembershipLevelName | null;
  verificationLevel: UserVerificationLevelEnum;
  profilePhoto: string | null;
  profilePhotoSmall: string | null;
  profilePhotoMedium: string | null;
  profilePhotoLarge: string | null;
  profileBanner: string | null;
  canReceivePayment: boolean;
  exhaustedTradeLimit: boolean;
  isAddressVerified: boolean;
  website: string | null;
  urlInstagram: string | null;
  urlFacebook: string | null;
  urlTiktok: string | null;
  urlTwitter: string | null;
  urlWhatNot: string | null;
};

export type UserLinkDto = Pick<
  UserDto,
  | 'id'
  | 'verificationLevel'
  | 'membershipLevel'
  | 'userName'
  | 'profilePhoto'
  | 'profilePhotoSmall'
  | 'profilePhotoMedium'
  | 'profilePhotoLarge'
  | 'profileBanner'
>;

export type UserId = UserDto['id'];

export type UserWithFollowingDto = UserDto & {
  following: boolean;
  followersCount: number;
};

export type UserShowcaseSearchDto = UserDto & {
  followersCount: number;
  itemsCount: number;
  ratedCount: number;
};

export type UserFullDto = UserDto & {
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  email: string;
  unconfirmedEmail: string | null;
  isEmailVerified: boolean;
  secureCheckDocumentUrl: string | null;
  phoneNo: string | null;
  unconfirmedPhoneNo: string | null;
  paymentId: string | null;
  transactionId: string | null;
  userRoleType: string;
  ratedCount: number;
  gender: string | null;
  birthDate: Date | null;
  introduction: string | null;
  address1: string | null;
  address2: string | null;
  state: string | null;
  city: string | null;
  country: string | null;
  postCode: string | null;
  isMediator: boolean;
  points: number | null;
  freeTradesAvailable: number | null;
  profileLevel: number | null;
  facebookId: string | null;
  isPhoneVerified: boolean;
  isSignUpCompleted: boolean;
  stripeCustomerId: string;
  stripeSubscriptionId: string | null;
  stripeAccountId: string | null;
  profilePhotoMetadata: ProfilePhotoMetadata | null;
  isForcedSubscription: boolean;
  membershipLevelId: number | null;
  liveStreamIvsChannelId: number | null;
  charitySupportersCountTier: 1 | 2 | 3 | 4 | 5 | 6 | 7 | null;
  optedInWholesale: boolean;
  membershipExpirationDate?: Date | null;
};

export interface UserUpdateDto {
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  charitySupportersCountTier?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  userName?: string;
  profilePhoto?: string | null;
  profilePhotoMedium?: string | null;
  profilePhotoSmall?: string | null;
  gender?: string | null;
  phoneNo?: string | null;
  email?: string;

  /** UTC ISO datetime string `YYYY-MM-DDTHH:mm:ss.sssZ`. */
  birthDate?: string | null;

  introduction?: string | null;
  address1?: string | null;
  address2?: string | null;
  state?: string | null;
  country?: string | null;
  city?: string | null;
  postCode?: string | null;

  isSignUpCompleted?: boolean | null;

  website?: string | null;
  urlInstagram?: string | null;
  urlFacebook?: string | null;
  urlTiktok?: string | null;
  urlTwitter?: string | null;
  urlWhatNot?: string | null;
}

export function toUserDto(user: UserFullDto): UserDto {
  return {
    rating: user.rating,
    id: user.id,
    verificationLevel: user.verificationLevel,
    membershipLevel: user.membershipLevel,
    profilePhoto: user.profilePhoto,
    profilePhotoSmall: user.profilePhotoSmall,
    profilePhotoMedium: user.profilePhotoMedium,
    profilePhotoLarge: user.profilePhotoLarge,
    profileBanner: user.profileBanner,
    userName: user.userName,
    canReceivePayment: user.canReceivePayment,
    exhaustedTradeLimit: user.exhaustedTradeLimit,
    isAddressVerified: user.isAddressVerified,
    website: user.website,
    urlFacebook: user.urlFacebook,
    urlInstagram: user.urlInstagram,
    urlTiktok: user.urlTiktok,
    urlTwitter: user.urlTwitter,
    urlWhatNot: user.urlWhatNot,
  };
}

export interface UserForAdminDTO extends UserDto {
  email: string;
  phoneNo?: string;
}

export interface ExistDto {
  exists: boolean;
}

export interface DiscountCodeDto {
  discountCode: string;
}

// #### TRADE TYPES !!!

export enum TradeOfferType {
  BUY = 'buy',
  CASH_OFFER = 'cash-offer',
  TRADE = 'trade',
  // FIXME: remove wanted item virtual offer type
  WANTED_ITEM_OFFER = 'wanted-item-offer',
  AUCTION = 'auction',
}

export const TradeOfferTypeName: Record<TradeOfferType, string> = {
  buy: INSTANT_PURCHASES_ENABLED ? 'Purchase' : 'Buy Offer',
  'cash-offer': 'Cash offer',
  trade: 'Trade',
  'wanted-item-offer': 'Wanted Item Offer',
  auction: INSTANT_PURCHASES_ENABLED ? 'Purchase' : 'Auction offer',
};

export enum SoldItemOfferType {
  BUY = 'buy',
  CASH_OFFER = 'cash-offer',
  AUCTION = 'auction',
}

export const SoldItemOfferTypeName: Record<SoldItemOfferType, string> = {
  buy: 'Purchase',
  'cash-offer': 'Cash offer',
  auction: 'Auction',
};

export enum TradeOfferStatus {
  ABORTED = 'aborted',
  EXPIRED = 'expired',
  INVALID = 'invalid',
  PROPOSED = 'proposed',
  DECLINED = 'declined',
  COUNTERED = 'countered',
  CANCELED = 'canceled',
  WAITING_DELIVERY_AGREEMENT = 'waiting-delivery-agreement',
  PAYMENT_PENDING = 'payment-pending',
  ACCEPTED = 'accepted',
  DONE = 'done',
}

export enum TradeOfferReplyType {
  ACCEPT = 'accept',
  CONSIDERING = 'considering',
  COUNTER = 'counter',
  DECLINE = 'decline',
}

export interface TradeOfferItemDto {
  id: number;
  quantity: number;
  priceShipping: number;
  name: string;
  price: number;
  itemImages: string[];
  rating: number;
  reviewsCount: number;
  ratedCount: number;
  offerType?: SoldItemOfferType;
}

export interface TradeOfferFundsCreateDto {
  payerId: number | 'currentUser';
  beneficiaryId: number;
  amountInCents: number;
}

export interface TradeOfferFundsDto {
  payerId: number | 'currentUser';
  beneficiaryId: number;
  amountInCents: number;
  discountInCents: number | null;
  shippingAmountInCents: number;
  taxAmountInCents: number;
  transactionFeeInCents: number;
  /**
   * Only available for seller. Buyer does not know if sell fee is applied.
   */
  sellFeeInCents: number | null;
  refundedInCents: number | null;
}

export type WithPayerId = {
  payerId: number;
};

export function assertHasPayerId<T extends TradeOfferFundsDto>(
  dto: T,
  message?: string | Error,
): asserts dto is T & WithPayerId {
  assert(dto.payerId !== 'currentUser', message);
}

export interface TradeOfferCreateDto {
  type: 'buy' | 'cash-offer' | 'trade';

  /**
   * User id to whom the trade offer is proposed. Note that it always stays the same within Trade.
   * You DON'T change direction (which user is "from" and which user is "to") when counter offer happens.
   */
  toUserId: number;
  /** Can only be present if trade offer type is `trade`. */
  itemsOffered?: TradeOfferItemCreateDto[] | null | undefined;
  itemsRequested: TradeOfferItemCreateDto[];
  couponCode?: string;

  /** Must be non-empty if trade offer type is `buy`. */
  funds: TradeOfferFundsCreateDto[];
  message: string;

  isInstantPurchase?: boolean;
}

export type ImageToProcess = { url: string; name: string; type: ImageMimeType };

export type TradeOfferItemCreateDto = {
  id: number;
  quantity: number;
};

export type TradeOfferCounterCreateDto = {
  id: number;
  type: 'buy' | 'cash-offer' | 'trade';

  /** Can only be present if trade offer type is `trade`. */
  itemsOffered?: TradeOfferItemCreateDto[];
  itemsRequested: TradeOfferItemCreateDto[];

  funds: TradeOfferFundsCreateDto[];
};

export type TradeOfferUserDto = {
  id: number;
  userName: string;
  membershipLevel: MembershipLevelName | null;
  verificationLevel?: 'not-verified' | 'contact-info-verified' | 'identity-verified';
  profilePhotoMetadata: ProfilePhotoMetadata | null;
};

export type TradeOffersCountDto = {
  buy: number;
  'cash-offer': number;
  trade: number;
};

/**
 * "*-by-A" denotes a completed action done by A.
 * "*-from-A" denotes a pending action expected to be done by A.
 */
export type TradeOfferDetailedStatusEnum =
  | 'proposed-by-A'
  | 'proposed-by-B'
  | 'reply-pending-from-A'
  | 'reply-pending-from-B'
  | 'declined'
  | 'countered'
  | 'canceled'
  | 'expired'
  | 'invalid'
  | 'aborted'
  | 'waiting-delivery-reply-from-A'
  | 'waiting-delivery-reply-from-B'
  | 'payment-pending-from-A'
  | 'payment-pending-from-B'
  | 'payment-done-by-A'
  | 'payment-done-by-B'
  | 'shipping-pending-from-A'
  | 'shipping-pending-from-B'
  | 'shipping-sent-by-A'
  | 'shipping-sent-by-B'
  | 'finalize-pending-from-A'
  | 'finalize-pending-from-B'
  | 'shipping-received-by-A'
  | 'shipping-received-by-B'
  | 'done';

export type TradeOfferDto = {
  id: number;
  tradeId: number;

  status: TradeOfferStatus;
  detailedStatus: TradeOfferDetailedStatusEnum[];
  type: TradeOfferType;
  isInstantPurchase: boolean;

  /* Buyer has requested to cancel the trade at a certain time */
  cancellationRequestedAt: string | null;

  /** User who proposed the trade. */
  fromUser: TradeOfferUserDto;

  /** User to whom the trade is proposed. */
  toUser: TradeOfferUserDto;

  /** Author of the trade offer. */
  userId: number;

  /** Can only be present if trade offer type is `trade`. */
  itemsOffered?: TradeOfferItemDto[] | null | undefined;
  itemsRequested: TradeOfferItemDto[] | null | undefined;

  /** Must be non-empty if trade offer type is `buy`. */
  funds: TradeOfferFundsDto[];
  message: string;

  delivery: TradeDeliveryDto | null;

  deliverySends: TradeDeliverySendAttributes[];

  payments: TradeOfferPaymentAttributes[];

  replies: TradeOfferReplyDto[];

  deliveryReceives: TradeDeliveryReceiveDto[];

  /** UTC ISO datetime string. */
  createdAt: string;

  /** UTC ISO datetime string. `null` if cannot be expired. */
  expireAt: string | null;
  finalizeNotificationSentAt: string | null;
  buyerStatedItemsAreNotDeliveredAt: string | null;
  sellerStatedItemsAreDeliveredAt: string | null;

  auctionInfoId?: number;
  auctionFantasyFulfilledAt?: string | null;
  auctionFantasyCanFulfill?: boolean;
};

export type TradeOfferPaymentStatus = Stripe.PaymentIntent.Status;

export interface TradeOfferPaymentAttributes {
  id: number;
  tradeOfferId: number;
  userId: number;
  status: TradeOfferPaymentStatus;
  amountInCents: number;
  stripePaymentIntentId: string;
  isTaxIncluded: boolean;
  createdAt: string;
  updatedAt: string;
}

// #### SIPPING TYPES !!!

export enum TradeDeliveryReplyType {
  ACCEPT = 'accept',
  DECLINE = 'decline',
  COUNTER = 'counter',
}

export enum TradeDeliveryMediatorDetailsStatus {
  PROPOSED = 'proposed',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  RECEIVED = 'received',
  APPROVED = 'approved',
}

export type TradeDeliveryCarrier = 'USPS' | 'UPS' | 'FedEx' | 'DHL';

export interface TradeDeliverySendCreateDTO {
  trackingNumber: string;
  images: string[];
  carrier: TradeDeliveryCarrier;
}

export interface TradeReviewCreateDTO {
  ratingDeal: number;
  ratingDescription: number;
  ratingPackaging: number;
  tradeId: number;
}

export interface TradeReview {
  ratingDeal: number;
  ratingDescription: number;
  ratingPackaging: number;
}

export interface TradeDeliverySendDTO {
  trackingNumber: string;
  images: string[];
  carrier: TradeDeliveryCarrier;
}

export interface TradeDeliverySendAttributes {
  id: number;
  trackingNumber: string;
  images: string[];

  userId: number;
  tradeDeliveryId: number;

  carrier: TradeDeliveryCarrier | null;
  createdAt: string;
}

export enum TradeMediatorReplyType {
  DECLINE = 'decline',
  ACCEPT = 'accept',
  RECEIVE = 'receive',
  APPROVE = 'approve',
  TRANSFER_TO_CUSTOMER = 'transfer-to-customer',
}

export interface TradeMediatorReplyCreateDto {
  type: TradeMediatorReplyType;
}

export interface SportDto {
  id: number;
  sportsName: string;
}

export interface TeamDto {
  id: number;
  teamName: string;
}

export interface PlayerDto {
  id: number;
  playerName: string;
}

export type WebSocketMessage =
  | NotificationWebSocketMessage
  | ChatMessageWebSocketMessage
  | LiveStreamWebSocketMessage;

export type DateDto = string;

export function fromDateDto(d: DateDto): Date {
  return new Date(d);
}
