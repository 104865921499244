import { AddItemsFloatingPanel } from '@/features/trade/components/addItemsFloatingPanel/AddItemsFloatingPanel';
import { useIsMobile } from '@/hooks/useIsMobile';
import { ReactNode } from 'react';
import Footer from './Footer/Footer';
import MainHeader from './MainHeader/MainHeader';

export default function Layout({ children }: { children: ReactNode }) {
  const isMobile = useIsMobile();
  return (
    <div className="flex flex-col justify-between">
      <div className={`${isMobile ? 'pt-[68px]' : ''}`}>
        <MainHeader />
        <div className="min-h-[80vh]">{children}</div>
      </div>
      <Footer />
      <AddItemsFloatingPanel />
    </div>
  );
}
