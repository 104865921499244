import { deleteFromStorage, useReadLocalStorage, writeStorage } from '@/utils/localStorage';
import { z } from 'zod';
import { offerDraftKeyType } from '../../offerDrafts.store';

const storageKey = 'addItemsFloatingPanel';

const addItemsFloatingPanelStateType = z.object({
  offerDraftKey: offerDraftKeyType,
  ownItemsActive: z.boolean(),
  openOnDesktop: z.boolean(),
  openOnMobile: z.boolean(),
});

export type AddItemsFloatingPanelState = z.infer<typeof addItemsFloatingPanelStateType>;

const getAddItemsFloatingPanel = (): AddItemsFloatingPanelState => {
  const value = localStorage.getItem(storageKey);
  return value === null ? {} : JSON.parse(value);
};

const clearIfInvalid = () => {
  if (!process.browser) return;

  try {
    addItemsFloatingPanelStateType.parse(getAddItemsFloatingPanel());
  } catch (_) {
    deleteFromStorage(storageKey);
  }
};

clearIfInvalid();

export const useAddItemsFloatingPanelState = () => {
  return useReadLocalStorage<AddItemsFloatingPanelState>(storageKey);
};

export const saveAddItemsFloatingPanelState = (
  updater: (data: AddItemsFloatingPanelState | null) => AddItemsFloatingPanelState | null,
) => {
  writeStorage(storageKey, updater(getAddItemsFloatingPanel()));
};
