import { LOAD_SHIPPING_DETAILS } from '../actions/actionTypes';
import { IAction } from '../types';

interface IState {
  shippingDetails: any;
}

const initialState: IState = {
  shippingDetails: null,
};

const shippingReducer = (state = initialState, action: IAction): IState => {
  switch (action.type) {
    case LOAD_SHIPPING_DETAILS:
      return {
        ...state,
        shippingDetails: action.payload,
      };

    default:
      return state;
  }
};

export default shippingReducer;
