import { setDataForCurrentUserDataQuery } from '@/api/user/user.api';
import {
  setCurrentUserPersistentData,
  useCurrentUserPersistentData,
} from '@/features/user/currentUser.service';
import Router from 'next/router';
import { Dispatch } from 'redux';
import { RESET_CHAT_STATE } from '../../actions/actionTypes';
import { useOnEnabled } from '../../hooks/useOnEnabled';
import routes from '../../routes/routes';
import { IAction, UserFullDto } from '../../types';
import { useAppDispatch } from '../../utils';
import { clearStorage } from '../../utils/localStorage';
import { queryClient } from '../../utils/queryClient';

export function logout() {
  setCurrentUserPersistentData(null);
}

export function login({
  user,
  accessToken,
  returnUrl,
}: {
  user: UserFullDto;
  accessToken: string;
  returnUrl?: string | null;
}) {
  setCurrentUserPersistentData({
    id: user.id,
    accessToken,
    verificationModalShownAt: new Date(),
  });
  setDataForCurrentUserDataQuery(user);

  if (returnUrl === null) {
    // do nothing
  } else if (!user.isSignUpCompleted) {
    Router.push(routes.setupAccount({ returnUrl }));
  } else if (returnUrl) {
    Router.push(returnUrl);
  } else {
    Router.push(routes.home);
  }
}

function applyLogout(dispatch: Dispatch<IAction>) {
  // has to use history instead of Router because otherwise existing components try to rerender with null user data BEFORE it switches url
  Router.push(routes.home);
  clearStorage();
  // just invalidate queries is not enough as it will continue use the cache
  // so clearing cache to force TRUE invalidation.
  queryClient.clear();
  dispatch({ type: RESET_CHAT_STATE });
}

function applyLogin() {
  // just invalidate queries is not enough as it will continue use the cache
  // so clearing cache to force TRUE invalidation.
  queryClient.clear();
}

/**
 * We treat local storage value under key `userData` as a source of truth for our logged in state.
 */
export function useAuthService() {
  const dispatch = useAppDispatch();
  const userData = useCurrentUserPersistentData();
  useOnEnabled(() => {
    // Logout whenever local storage data becomes null after being non-null.
    applyLogout(dispatch);
  }, userData == null);
  useOnEnabled(() => {
    // Login whenever local storage data becomes non-null after being null.
    applyLogin();
  }, userData != null);
}
