import { UserFullDto } from '../../types';

export const postCodeRegexp = /^[0-9-]*$/;

export function isAddressSet(user: UserFullDto): boolean {
  return user.isAddressVerified;
}

export function formatUserPersonalName(user: UserFullDto): string {
  return user.companyName ?? `${user.firstName} ${user.lastName}`;
}
