import { message } from 'antd';
import { UseQueryResult } from 'react-query';
import { UserDto, UserId } from '../../types';
import { ApiError, extendMutationHook } from '../../utils/api';
import { livestreamUsername } from '../../utils/liveStream';
import UnreachableCaseError from '../../utils/UnreachableCaseError';
import { getMembershipCapabilities } from '../membership/membership.capabilities';
import {
  useLiveStreamCancelMutation,
  useLiveStreamCurrentQuery,
  useLiveStreamGetQuery,
} from './liveStream.api';
import { LiveStreamDto, LiveStreamId, LiveStreamScheduledDto } from './liveStream.dto';

export enum MediaStreamTypes {
  FIRST_CAMERA = 'camera1',
  FIRST_MIC = 'mic1',
  SECOND_CAMERA = 'camera2',
}

export function getCanLiveStreamYoutube({ user }: { user: UserDto }) {
  return user.userName === livestreamUsername;
}

export function getCanLiveStreamIvs({ user }: { user: UserDto }) {
  const capabilities = getMembershipCapabilities(user.membershipLevel);
  return capabilities.liveStream;
}

export function getCanLiveStreamAgora({ user }: { user: UserDto }) {
  const capabilities = getMembershipCapabilities(user.membershipLevel);
  return capabilities.liveStream;
}

export function getCanLiveStream({ user }: { user: UserDto }) {
  return (
    getCanLiveStreamYoutube({ user }) ||
    getCanLiveStreamIvs({ user }) ||
    getCanLiveStreamAgora({ user })
  );
}

export function getYoutubeThumbnailUrl({ videoId }: { videoId: string }) {
  return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
}

export function getLiveStreamThumbnailUrl({ liveStream }: { liveStream: LiveStreamDto }) {
  if (liveStream.type === 'youtube') {
    return getYoutubeThumbnailUrl({ videoId: liveStream.youtubeEventId });
  }
  if (liveStream.type === 'ivs') {
    if ('coverPhoto' in liveStream && liveStream.coverPhoto) return liveStream.coverPhoto;
    if ('recordedMedia' in liveStream && liveStream.recordedMedia.success) {
      return liveStream.recordedMedia.data.thumbnailUrl;
    }
    return null;
  }
  if (liveStream.type === 'agora') {
    if ('coverPhoto' in liveStream && liveStream.coverPhoto) return liveStream.coverPhoto;
    if ('recordedMedia' in liveStream) {
      return liveStream.recordedMedia.thumbnailUrl ?? null;
    }
    return null;
  }
  throw new UnreachableCaseError(liveStream);
}

export function useLiveStreamGetByIdOrCurrentQuery({
  liveStreamId,
  userId,
  forLiveAuction,
  refetchInterval,
}: {
  liveStreamId: LiveStreamId | undefined;
  userId: UserId;
  forLiveAuction?: boolean;
  refetchInterval?: number;
}): UseQueryResult<LiveStreamDto | null, ApiError> {
  const getCurrent = liveStreamId == null;
  const getById = liveStreamId != null;
  const getCurrentLiveStreamQuery = useLiveStreamCurrentQuery(
    {
      userId,
      forLiveAuction,
    },
    {
      enabled: getCurrent,
      refetchInterval,
    },
  );
  const getLiveStreamQuery = useLiveStreamGetQuery(
    {
      liveStreamId: liveStreamId!,
      forLiveAuction,
    },
    {
      enabled: getById,
      refetchInterval,
    },
  );

  if (getCurrent) return getCurrentLiveStreamQuery;
  if (getById) return getLiveStreamQuery;
  throw new UnreachableCaseError();
}

export const useLiveStreamCancel = extendMutationHook(useLiveStreamCancelMutation)({
  mapVariables({ liveStream }: { liveStream: LiveStreamScheduledDto }) {
    return {
      liveStreamId: liveStream.id,
    };
  },
});

export async function handlePermissions() {
  let permissions = {
    audio: false,
    video: false,
  };
  let stream = null;
  try {
    stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    for (const track of stream.getTracks()) {
      track.stop();
    }
    permissions = { video: true, audio: true };
  } catch (err) {
    permissions = { video: false, audio: false };
    console.error(err);
  }
  if (!permissions.video && !permissions.audio) {
    message.error('Failed to get video and audio permissions.');
  } else if (!permissions.video) {
    message.error('Failed to get video permissions.');
  } else if (!permissions.audio) {
    message.error('Failed to get audio permissions.');
  }
  return { stream, permissions };
}
