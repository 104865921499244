import {
  TradeDeliverySendCreateDTO,
  TradeDeliverySendDTO,
  TradeOfferCounterCreateDto,
  TradeOfferCreateDto,
  TradeOfferDto,
  TradeOfferReplyType,
  TradeOffersCountDto,
  TradeReviewCreateDTO,
} from '../../types';
import {
  buildApiMutationHook,
  buildApiQueryHook,
  buildApiQueryNoParamsHook,
  handleApiResult,
} from '../../utils/api';
import { queryClient } from '../../utils/queryClient';
import axiosI from '../base.api';
import { StripePaymentIntentDto } from '../stripe/stripe.dto';
import { TradeOfferReplyWithCounterOfferDTO } from './tradeOffer.dto';

export type ReplyTradeActionParams = { message?: string; tradeOfferId: number } & (
  | {
      type: Exclude<TradeOfferReplyType, TradeOfferReplyType.COUNTER>;
    }
  | {
      type: TradeOfferReplyType.COUNTER;
      counterOffer: TradeOfferCounterCreateDto;
    }
);

export type ProposeTradeOfferResult = {
  tradeOffer: TradeOfferDto;
  instantPurchasePaymentIntent?: StripePaymentIntentDto;
};

export const useTradeOfferProposeMutation = buildApiMutationHook<
  TradeOfferCreateDto,
  ProposeTradeOfferResult
>((data) => axiosI.post('/trade/offer/propose', data));

export const tradeOfferReply = async ({ tradeOfferId, ...other }: ReplyTradeActionParams) => {
  return handleApiResult<TradeOfferReplyWithCounterOfferDTO>(
    axiosI.post(`/trade/offer/reply/${tradeOfferId}`, other),
  );
};

export const useTradeOfferReplyMutation = buildApiMutationHook<
  ReplyTradeActionParams,
  TradeOfferReplyWithCounterOfferDTO
>(({ tradeOfferId, ...other }) => axiosI.post(`/trade/offer/reply/${tradeOfferId}`, other));

export const useFindOrCreateTradeOfferPaymentIntent = buildApiMutationHook<
  { tradeOfferId: number },
  StripePaymentIntentDto
>(({ tradeOfferId }) => axiosI.post(`/trade/offer/${tradeOfferId}/paymentIntents`));

type LoadTradeOfferParams = {
  tradeOfferId: number;
  checkPayment?: boolean;
};

export const useTradeOfferQuery = buildApiQueryHook<LoadTradeOfferParams, TradeOfferDto>(
  'tradeOffer',
  (params) =>
    axiosI.get(`trade/offer/${params.tradeOfferId}`, {
      params: { checkPayment: params.checkPayment || undefined },
    }),
);

export const useTradeCountQuery = buildApiQueryNoParamsHook<TradeOffersCountDto>('tradeCount', () =>
  axiosI.get<{ data: TradeOffersCountDto }>(`/trade/offer/count`),
);

export const useLoadAllTradeQuery = buildApiQueryNoParamsHook<TradeOfferDto[]>('allTrade', () =>
  axiosI.get('/trade/offer'),
);

export const useCancelTradeOfferMutation = buildApiMutationHook<{ tradeOfferId: number }>(
  ({ tradeOfferId }) => axiosI.post(`/trade/offer/cancel/${tradeOfferId}`),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries('allTrade');
      options?.onSuccess?.(...args);
    },
  }),
);

export const useCancellationRequestTradeOfferMutation = buildApiMutationHook<{
  tradeOfferId: number;
}>(
  ({ tradeOfferId }) => axiosI.post(`/trade/offer/cancellation/request/${tradeOfferId}`),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries('allTrade');
      options?.onSuccess?.(...args);
    },
  }),
);

export const useCancellationConfirmTradeOfferMutation = buildApiMutationHook<{
  tradeOfferId: number;
}>(({ tradeOfferId }) => axiosI.post(`/trade/offer/cancellation/confirm/${tradeOfferId}`));

export const useShippingSendMutation = buildApiMutationHook<
  { tradeDeliveryId: number; data: TradeDeliverySendCreateDTO },
  void
>(
  ({ tradeDeliveryId, data }) => axiosI.post(`trade/delivery/send/${tradeDeliveryId}`, data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(['trade/delivery/send/', args[1].tradeDeliveryId]);
      options?.onSuccess?.(...args);
    },
  }),
);

export const useStateItemsAreDelivered = buildApiMutationHook<{ tradeDeliveryId: number }, void>(
  ({ tradeDeliveryId }) => axiosI.post(`trade/delivery/send/deliveredStatement/${tradeDeliveryId}`),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries([
        'trade/delivery/send/deliveredStatement',
        args[1].tradeDeliveryId,
      ]);
      options?.onSuccess?.(...args);
    },
  }),
);

export const useShippingSendQuery = buildApiQueryHook<
  { tradeDeliveryId: number },
  TradeDeliverySendDTO
>(
  (params) => ['trade/delivery/send/', params.tradeDeliveryId],
  (params) => axiosI.get(`trade/delivery/send/${params.tradeDeliveryId}`),
);

export const useNotifyPotentialBuyerSendMutation = buildApiMutationHook<
  { wantedItemId: number; offeredItemId: number; otherUserId: number },
  void
>(
  ({ wantedItemId, offeredItemId, otherUserId }) =>
    axiosI.post(`/api/trade/offer/notify-wanted-item`, {
      wantedItemId,
      offeredItemId,
      otherUserId,
    }),
  (options) => ({
    ...options,
    onSuccess(...args) {
      options?.onSuccess?.(...args);
    },
  }),
);
export const useTradeReview = buildApiMutationHook<{ data: TradeReviewCreateDTO }, void>(
  ({ data }) => axiosI.post(`trade/review`, data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      options?.onSuccess?.(...args);
    },
  }),
);
