import { message } from 'antd';
import { MessageType } from 'antd/lib/message';
import dayjs, { Dayjs } from 'dayjs';
import { isApiError } from './api';

const throttleInterval = 5000;

/**
 * Prevents duplicate error messages from bombarding user with popups.
 * Like when api is unavailable, all requests are failing at the same time with the same error.
 * With this patch, only one error message is shown in such cases.
 */
export function patchAntdMessageError() {
  const original = message.error;

  const invocations = new Map<string, Dayjs>();

  const patched: typeof message.error = function patchedAntdMessageError(...args) {
    const err = args[0];
    const errHash = getErrHash(err);

    if (errHash) {
      const currentInvocation = dayjs();
      const lastInvocation = invocations.get(errHash);
      if (lastInvocation) {
        const msPassed = currentInvocation.diff(lastInvocation);
        if (msPassed < throttleInterval) return undefined as unknown as MessageType;
      }
      invocations.set(errHash, currentInvocation);
    }

    return original.call(message, ...args);
  };

  message.error = patched.bind(message);
  return function unpatch() {
    message.error = original;
  };
}

function getErrHash(err: unknown): string | undefined {
  if (isApiError(err)) return err.message;
  if (typeof err === 'string') return err;
  return undefined;
}
