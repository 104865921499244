import { DISABLE_LOADER, ENABLE_LOADER } from '../actions/actionTypes';
import { IAction } from '../types';

interface IState {
  pageLoader: boolean;
}

const initialState: IState = {
  pageLoader: false,
};

const loaderReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case ENABLE_LOADER:
      return { ...state, pageLoader: true };
    case DISABLE_LOADER:
      return { ...state, pageLoader: false };
    default:
      return state;
  }
};

export default loaderReducer;
