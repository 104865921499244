import { combineReducers } from 'redux';
import chatReducer from './chatReducer';
import loaderReducer from './loaderReducer';
import shippingReducer from './shippingReducer';
import uiStateReducer from './uiStateReducer';

const allReducers = () =>
  combineReducers({
    loaderReducer,
    shippingReducer,
    uiStateReducer,
    chatReducer,
  });

export default allReducers;
