import {
  buildApiMutationHook,
  buildApiQueryHook,
  buildApiQueryNoParamsHook,
} from '../../utils/api';
import { queryClient } from '../../utils/queryClient';
import axiosI from '../base.api';
import { NotificationDTO, NotificationId, NotificationsCountDTO } from './notification.dto';

export function invalidateNotificationQueries() {
  queryClient.invalidateQueries('notifications');
}

export function invalidateUnreadNotificationQueries() {
  queryClient.invalidateQueries('unread_notifications_count');
}

export const useAllNotificationsCountQuery = buildApiQueryNoParamsHook<NotificationsCountDTO>(
  'notifications_count',
  () => axiosI.get('/notifications/count'),
);

export const useUnreadNotificationsCountQuery = buildApiQueryNoParamsHook<NotificationsCountDTO>(
  'unread_notifications_count',
  () => axiosI.get('/notifications/unread-count'),
);

export type NotificationsAPIParams = {
  limit?: number;
  offset?: number;
};

export const useNotificationsQuery = buildApiQueryHook<NotificationsAPIParams, NotificationDTO[]>(
  'notifications',
  (params) => axiosI.get('/notifications', { params }),
);

type MarkNotificationAsReadParams = {
  id: NotificationId;
};

export const useMarkNotificationAsReadMutation = buildApiMutationHook<
  MarkNotificationAsReadParams,
  void
>(
  (data) => axiosI.post('/notifications/mark-as-read', data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      invalidateNotificationQueries();
      invalidateUnreadNotificationQueries();
      options?.onSuccess?.(...args);
    },
  }),
);

export const useMarkAllNotificationsAsReadMutation = buildApiMutationHook<void>(
  () => axiosI.post('/notifications/mark-all-as-read'),
  (options) => ({
    ...options,
    onSuccess(...args) {
      invalidateNotificationQueries();
      invalidateUnreadNotificationQueries();
      options?.onSuccess?.(...args);
    },
  }),
);
