import axiosI from '../../api/base.api';
import { buildApiMutationHook, buildApiQueryHook, buildApiQueryHookFull } from '../../utils/api';
import { queryClient } from '../../utils/queryClient';
import {
  LiveStreamCancelledDto,
  LiveStreamDto,
  LiveStreamScheduledDto,
  LiveStreamStartedDto,
} from './liveStream.dto';

export type LiveStreamListParams = { forLiveAuction?: boolean };

export const { useLiveStreamListQuery } = buildApiQueryHookFull('LiveStreamListQuery')<
  LiveStreamListParams,
  LiveStreamScheduledDto[] | LiveStreamStartedDto[]
>(
  (params) => ['live-stream', 'list', params],
  (params) => axiosI.get('/live-stream/list', { params }),
  (raw) => raw,
);

export const useLiveStreamHistoryQuery = buildApiQueryHook<
  { userId?: number; offset?: number; limit?: number },
  LiveStreamDto[]
>(
  (params) => ['live-stream', 'history', params],
  ({ userId, offset, limit }) =>
    axiosI.get(`/live-stream/history/${userId ?? ''}`, { params: { offset, limit } }),
);

export const useCountLiveStreamHistoryQuery = buildApiQueryHook<{ userId: number }, number>(
  (params) => ['live-stream', 'history-count', params],
  ({ userId }) => axiosI.get(`/live-stream/history/${userId}/count`),
);

export type LiveStreamCurrentParams = { userId: number; forLiveAuction?: boolean };
export type LiveStreamCurrentResult = LiveStreamDto | null;
export const {
  keyLiveStreamCurrentQuery,
  setDataForLiveStreamCurrentQuery,
  useLiveStreamCurrentQuery,
} = buildApiQueryHookFull('LiveStreamCurrentQuery')<
  LiveStreamCurrentParams,
  LiveStreamCurrentResult
>(
  (params) => ['live-stream', 'current', params],
  ({ userId, forLiveAuction }) =>
    axiosI.get(`/live-stream/current/${userId}`, {
      params: {
        ...(forLiveAuction ? { forLiveAuction: true } : {}),
      },
    }),
  (raw) => raw,
  {
    onSuccess(data) {
      if (!data) return;

      setDataForLiveStreamGetQuery({ liveStreamId: data.id }, data);
    },
  },
);

export type LiveStreamGetParams = { liveStreamId: number; forLiveAuction?: boolean };
export type LiveStreamGetResult = LiveStreamDto | null;
export const { keyLiveStreamGetQuery, setDataForLiveStreamGetQuery, useLiveStreamGetQuery } =
  buildApiQueryHookFull('LiveStreamGetQuery')<LiveStreamGetParams, LiveStreamGetResult>(
    (params) => ['live-stream', 'get', params],
    ({ liveStreamId, forLiveAuction }) =>
      axiosI.get(`/live-stream/get/${liveStreamId}`, {
        params: {
          ...(forLiveAuction ? { forLiveAuction: true } : {}),
        },
      }),
    (raw) => raw,
  );

export type CancelLiveStreamBody = {
  liveStreamId: number;
};

export const useLiveStreamCancelMutation = buildApiMutationHook<
  CancelLiveStreamBody,
  LiveStreamCancelledDto
>((data) => axiosI.post('/live-stream/cancel', data), {
  onSuccess() {
    queryClient.invalidateQueries('live-stream');
  },
});

export const invalidateCurrentLiveStreamQuery = async (
  params: {
    userId: number;
    forLiveAuction?: boolean;
  },
  refetch?: boolean,
) => {
  if (!params.forLiveAuction) delete params.forLiveAuction;
  const key = keyLiveStreamCurrentQuery(params);
  await queryClient.invalidateQueries(key);
  if (refetch) await queryClient.refetchQueries(key);
};

export const invalidateLiveStreamQueryById = async (
  params: {
    liveStreamId: number;
    forLiveAuction?: boolean;
  },
  refetch?: boolean,
) => {
  if (!params.forLiveAuction) delete params.forLiveAuction;
  const key = keyLiveStreamGetQuery(params);
  await queryClient.invalidateQueries(key);
  if (refetch) await queryClient.refetchQueries(key);
};

export const useLiveStreamRemovelMutation = buildApiMutationHook<
  CancelLiveStreamBody,
  LiveStreamCancelledDto
>((data) => axiosI.post('/live-stream/remove', data), {
  onSuccess() {
    queryClient.invalidateQueries('live-stream');
  },
});
