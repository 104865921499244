import { CouponDiscountDto } from '@/api/coupon/coupon.api';
import { ShowcaseItemDto } from '@/api/showcaseItems/showcaseItems.dto';
import {
  TradeDeliveryShippingDetailsDto,
  TradeDeliveryType,
} from '@/api/trade/tradeDelivery/tradeDelivery.dto';
import routes from '@/routes/routes';
import {
  TradeOfferDto,
  TradeOfferReplyType,
  TradeOfferStatus,
  TradeOfferType,
  UserDto,
} from '@/types';
import UnreachableCaseError from '@/utils/UnreachableCaseError';
import { anyPass, not, pipe } from 'ramda';
import { Merge } from 'type-fest';
import {
  MIN_CASH_OFFER_AMOUNT_IN_CENTS,
  SUBTRACT_PERCENT_FOR_STRIPE_FEES,
  TRADE_MESSAGING_ENABLED,
} from './offer.constants';
import { OfferDraftDto } from './offerDrafts.store';

type OfferAndCurrentUserId<K extends keyof TradeOfferDto = keyof TradeOfferDto> = {
  offer: Pick<TradeOfferDto, K>;
  currentUserId: number;
};

type OfferDraftAndCurrentUserId<K extends keyof OfferDraftDto = keyof OfferDraftDto> = {
  offer: Pick<OfferDraftDto, K>;
  currentUserId: number;
};

type OfferOrDraft = TradeOfferDto | OfferDraftDto;

type OfferOrDraftAndCurrentUserId<K extends keyof OfferOrDraft = keyof OfferOrDraft> = {
  offer: Pick<OfferOrDraft, K>;
  currentUserId: number;
};

export const getItemsShippingDetails = ({
  isOwnItem,
  isOfferStartedByMe,
  toUserDetails,
  fromUserDetails,
}: {
  isOwnItem: boolean;
  isOfferStartedByMe: boolean;
  toUserDetails: null | TradeDeliveryShippingDetailsDto;
  fromUserDetails: null | TradeDeliveryShippingDetailsDto;
}) => {
  if (isOwnItem) {
    if (isOfferStartedByMe) {
      return toUserDetails;
    } else {
      return fromUserDetails;
    }
  } else {
    if (isOfferStartedByMe) {
      return fromUserDetails;
    } else {
      return toUserDetails;
    }
  }
};

export const getIsOfferStartedByMe = (params: OfferDraftAndCurrentUserId<'fromUser'>) => {
  return params.currentUserId === params.offer.fromUser.id;
};

// long name to make distinguish is from getIsOfferStartedByMe
export const getIsOfferedOrCounteredByMe = (params: OfferOrDraftAndCurrentUserId<'userId'>) => {
  return params.currentUserId === params.offer.userId;
};

export const getIsOfferedOrCounteredByOtherUser = (
  params: OfferOrDraftAndCurrentUserId<'userId'>,
) => {
  return !getIsOfferedOrCounteredByMe(params);
};

export const getTradeOtherUser = <T extends { id: number }>(params: {
  currentUserId: number;
  offer: {
    toUser: T;
    fromUser: T;
  };
}) => {
  return getIsOfferStartedByMe(params) ? params.offer.toUser : params.offer.fromUser;
};

export const getIsTrade = ({ type }: { type: TradeOfferType }) => {
  return type === TradeOfferType.TRADE || type === TradeOfferType.WANTED_ITEM_OFFER;
};

export const getIsWantedItemOffer = ({ type }: { type: TradeOfferType }) => {
  return type === TradeOfferType.WANTED_ITEM_OFFER;
};

export const getCanHaveOfferedItems = getIsTrade;

export const getIsCashOffer = ({ type }: { type: TradeOfferType }) => {
  return type === TradeOfferType.CASH_OFFER;
};

export const getIsTradeOrCashOffer = anyPass([getIsTrade, getIsCashOffer]);

export const getIsBuyOffer = (x: { type: TradeOfferType }): x is { type: TradeOfferType.BUY } => {
  return x.type === TradeOfferType.BUY;
};

export const getCanEditFunds = getIsTradeOrCashOffer;

export const warnIfDealFavorsOneSide = getIsTradeOrCashOffer;

export const getIsProposed = ({ status }: { status: TradeOfferStatus }) => {
  return status === TradeOfferStatus.PROPOSED;
};

export const getIsWaitingDeliveryAgreement = ({ status }: { status: TradeOfferStatus }) => {
  return status === TradeOfferStatus.WAITING_DELIVERY_AGREEMENT;
};

export const pickOwnItems = <Items>(
  params: {
    requested: Items;
    offered: Items;
  } & OfferOrDraftAndCurrentUserId<'fromUser'>,
): Items => {
  return params.offer.fromUser.id === params.currentUserId ? params.offered : params.requested;
};

export const pickTheirItems = <Items>(
  params: {
    requested: Items;
    offered: Items;
  } & OfferOrDraftAndCurrentUserId<'fromUser'>,
) => {
  return pickOwnItems(params) === params.requested ? params.offered : params.requested;
};

export const pickItems = <Items>(
  params: {
    ownItems: boolean;
    requested: Items;
    offered: Items;
  } & OfferOrDraftAndCurrentUserId<'fromUser'>,
) => {
  return params.ownItems ? pickOwnItems(params) : pickTheirItems(params);
};

export const getOfferOtherUserId = (
  params: OfferOrDraftAndCurrentUserId<'fromUser' | 'toUser'>,
) => {
  return params.currentUserId === params.offer.fromUser.id
    ? params.offer.toUser.id
    : params.offer.fromUser.id;
};

export const getCanSubmitOfferDraft = ({ offerDraft }: { offerDraft: OfferDraftDto }) => {
  if (offerDraft.itemsRequestedIds.length === 0) return false;
  if (getIsTrade(offerDraft)) return offerDraft.itemsOfferedIds.length > 0;
  if (getIsCashOffer(offerDraft))
    return (offerDraft.funds[0]?.amountInCents || 0) >= MIN_CASH_OFFER_AMOUNT_IN_CENTS;
  return true;
};

export const getCanCounter = (params: OfferAndCurrentUserId<'status' | 'userId' | 'type'>) => {
  return (
    getIsProposed(params.offer) &&
    getIsOfferedOrCounteredByOtherUser(params) &&
    (getIsTrade(params.offer) || getIsCashOffer(params.offer))
  );
};

export const getCanAccept = (params: OfferAndCurrentUserId<'status' | 'userId'>) => {
  return getIsProposed(params.offer) && getIsOfferedOrCounteredByOtherUser(params);
};

function checkTradeOfferCanBeCanceled(tradeOffer: TradeOfferDto) {
  const pass = () => ({ canBeCanceled: true } as const);
  const fail = (reason: 'trade-wasnt-requested-to-be-canceled' | 'trade-already-ended') =>
    ({
      canBeCanceled: false,
      reason,
    } as const);

  if (!tradeOffer.cancellationRequestedAt) {
    return fail('trade-wasnt-requested-to-be-canceled');
  }

  switch (tradeOffer.status) {
    case TradeOfferStatus.ABORTED:
    case TradeOfferStatus.COUNTERED:
    case TradeOfferStatus.CANCELED:
    case TradeOfferStatus.DECLINED:
    case TradeOfferStatus.DONE:
    case TradeOfferStatus.EXPIRED:
    case TradeOfferStatus.INVALID:
      return fail('trade-already-ended');
    case TradeOfferStatus.ACCEPTED:
    case TradeOfferStatus.PAYMENT_PENDING:
    case TradeOfferStatus.PROPOSED:
    case TradeOfferStatus.WAITING_DELIVERY_AGREEMENT:
      // do nothing
      break;
    default:
      throw new UnreachableCaseError(tradeOffer.status);
  }

  return pass();
}

export const getCanConfirmCancel = (params: OfferAndCurrentUserId) => {
  const { offer, currentUserId } = params;
  if (offer.userId !== currentUserId && checkTradeOfferCanBeCanceled(offer).canBeCanceled) {
    return true;
  }

  return false;
};

export const getCanStateItemsAreSent = (params: OfferAndCurrentUserId) => {
  const { offer, currentUserId } = params;
  if (
    offer.isInstantPurchase &&
    offer.userId !== currentUserId &&
    offer.buyerStatedItemsAreNotDeliveredAt &&
    !offer.sellerStatedItemsAreDeliveredAt
  ) {
    return true;
  }
  return false;
};

export const getCanRequestToCancel = (params: OfferAndCurrentUserId) => {
  const { offer, currentUserId } = params;
  if (
    offer.isInstantPurchase &&
    offer.status === 'proposed' &&
    offer.userId === currentUserId &&
    offer.cancellationRequestedAt === null
  ) {
    return true;
  }

  return false;
};

export const getCanDecline = getCanAccept;

export const getCanConsider = (params: OfferAndCurrentUserId<'status' | 'userId' | 'replies'>) => {
  return (
    getIsProposed(params.offer) &&
    getIsOfferedOrCounteredByOtherUser(params) &&
    !params.offer.replies.some((reply) => reply.type === TradeOfferReplyType.CONSIDERING)
  );
};

export const getCanAddMessage = (params: OfferAndCurrentUserId<'status' | 'userId'>) => {
  return TRADE_MESSAGING_ENABLED;
};

export const getCanWithdraw = (
  params: OfferAndCurrentUserId<'status' | 'userId' | 'isInstantPurchase'>,
) => {
  return (
    getIsProposed(params.offer) &&
    getIsOfferedOrCounteredByMe(params) &&
    !params.offer.isInstantPurchase
  );
};

export const getIsOfferPaidByUser = ({
  offer,
  userId,
}: {
  offer: Pick<TradeOfferDto, 'payments'>;
  userId: number;
}) => {
  return offer.payments.some(
    (payment) => payment.userId === userId && payment.status !== 'requires_payment_method', // TODO: find a better way to know if user already payed
  );
};

export const getUserOffersFunds = (params: OfferAndCurrentUserId) => {
  return params.offer.funds.some(
    (f) => f.payerId === params.currentUserId || f.payerId === 'currentUser',
  );
};

export const getIsPendingPaymentFromUser = (params: OfferAndCurrentUserId) => {
  return getUserOffersFunds(params) && !params.offer.isInstantPurchase;
};

export const getCanPay = ({ offer, currentUserId }: OfferAndCurrentUserId) => {
  if (getIsOfferPaidByUser({ offer, userId: currentUserId })) return false;

  if (offer.status !== TradeOfferStatus.PAYMENT_PENDING) return false;

  return getUserOffersFunds({ offer, currentUserId });
};

export const getIsTradeInitiator = (params: OfferAndCurrentUserId<'fromUser'>) => {
  return params.currentUserId === params.offer.fromUser.id;
};

export const getDidUserDeliverySends = (params: OfferAndCurrentUserId) => {
  return params.offer.deliverySends.some((send) => send.userId === params.currentUserId);
};

export const getUserDeliverySends = (params: OfferAndCurrentUserId) => {
  return params.offer.deliverySends.filter((send) => send.userId === params.currentUserId);
};

export const getOtherUserDeliverySends = (params: OfferAndCurrentUserId) => {
  return params.offer.deliverySends.filter((send) => send.userId !== params.currentUserId);
};

export const getCanSend = (params: OfferAndCurrentUserId) => {
  const { offer, currentUserId } = params;
  if (offer.status !== TradeOfferStatus.ACCEPTED) {
    return false;
  }

  if (offer.type === TradeOfferType.TRADE) {
    return !offer.deliverySends.some((send) => send.userId === currentUserId);
  } else if (
    offer.type === TradeOfferType.BUY ||
    offer.type === TradeOfferType.CASH_OFFER ||
    offer.type === TradeOfferType.AUCTION
  ) {
    const isInitiator = getIsTradeInitiator(params);
    return !isInitiator && !getDidUserDeliverySends(params);
  } else if (offer.type === TradeOfferType.WANTED_ITEM_OFFER) {
    throw new Error('impossible');
  } else {
    throw new UnreachableCaseError(offer.type);
  }
};

export const getCanEditShipping = (params: OfferAndCurrentUserId) => {
  const { offer } = params;
  if (offer.status !== TradeOfferStatus.ACCEPTED) {
    return false;
  }
  if (getDidUserDeliverySends(params)) return true;
  return false;
};

const getFromUserItems = ({ offer }: { offer: Pick<TradeOfferDto, 'itemsOffered'> }) => {
  return offer.itemsOffered || [];
};

const getToUserItems = ({ offer }: { offer: Pick<TradeOfferDto, 'itemsRequested'> }) => {
  return offer.itemsRequested || [];
};

export const getMyItems = ({
  offer,
  currentUserId,
}: OfferAndCurrentUserId<'fromUser' | 'itemsOffered' | 'itemsRequested'>) => {
  return offer.fromUser.id === currentUserId
    ? getFromUserItems({ offer })
    : getToUserItems({ offer });
};

export const getReceivingFunds = (params: OfferAndCurrentUserId<'funds'>) => {
  const receivingFunds = params.offer.funds.filter((f) => f.beneficiaryId === params.currentUserId);

  if (!receivingFunds.length) return null;

  return receivingFunds.reduce((acc, curr) => acc + curr.amountInCents, 0);
};

export const getSendingFunds = (params: OfferAndCurrentUserId<'funds'>) => {
  const sendingFunds = params.offer.funds.filter(
    (f) => f.payerId === params.currentUserId || f.payerId === 'currentUser',
  );

  if (!sendingFunds.length) return null;

  return sendingFunds.reduce((acc, curr) => acc + curr.amountInCents, 0);
};

export const getTheirItems = ({
  offer,
  currentUserId,
}: OfferAndCurrentUserId<'toUser' | 'itemsOffered' | 'itemsRequested'>) => {
  return offer.toUser.id === currentUserId
    ? getFromUserItems({ offer })
    : getToUserItems({ offer });
};

export const getIsReceivingItems = (
  params: OfferAndCurrentUserId<'toUser' | 'itemsOffered' | 'itemsRequested'>,
): boolean => getTheirItems(params).length > 0;

export const getIsSendingItems = (
  params: OfferAndCurrentUserId<'fromUser' | 'itemsOffered' | 'itemsRequested'>,
): boolean => getMyItems(params).length > 0;

export const getIsItemsWereSentByUser = ({
  userId,
  offer,
}: {
  userId: number;
  offer: Pick<TradeOfferDto, 'delivery' | 'deliverySends'>;
}) => {
  if (!offer.delivery) return false;

  switch (offer.delivery.type) {
    case TradeDeliveryType.SHIPPING: {
      return offer.deliverySends.some((s) => s.userId !== userId);
    }
    default:
      throw new UnreachableCaseError(offer.delivery.type);
  }
};

export const getCanUploadShippingProof = ({ offer, currentUserId }: OfferAndCurrentUserId) => {
  if (!offer.delivery) return false;
  if (offer.status !== TradeOfferStatus.ACCEPTED) return false;
  if (!getIsSendingItems({ offer, currentUserId })) return false;
  if (offer.deliverySends.some((s) => s.userId === currentUserId)) return false;

  return true;
};

export const getIsBuyableItem = (
  item: ShowcaseItemDto,
  owner: Pick<UserDto, 'canReceivePayment' | 'exhaustedTradeLimit'>,
  currentUser: UserDto | undefined,
) => {
  return (
    item.toSell &&
    !!item.quantityAvailable &&
    owner.canReceivePayment &&
    !owner.exhaustedTradeLimit &&
    !(currentUser?.exhaustedTradeLimit ?? false)
  );
};

export const getIsCharitableItem = (
  item: ShowcaseItemDto,
  owner: Pick<UserDto, 'canReceivePayment' | 'exhaustedTradeLimit'>,
  currentUser: UserDto | undefined,
) => {
  return (
    item.forCharity &&
    !!item.quantityAvailable &&
    owner.canReceivePayment &&
    !owner.exhaustedTradeLimit &&
    !(currentUser?.exhaustedTradeLimit ?? false)
  );
};

export const getIsCashOfferableItem = (item: ShowcaseItemDto, currentUser: UserDto | undefined) => {
  return (
    item.forCashOffer && !!item.quantityAvailable && !(currentUser?.exhaustedTradeLimit ?? false)
  );
};

export const getIsTradeableItem = (item: ShowcaseItemDto, currentUser: UserDto | undefined) => {
  return item.toTrade && !!item.quantityAvailable && !(currentUser?.exhaustedTradeLimit ?? false);
};

export const getIsAuctionableItem = (item: ShowcaseItemDto, currentUser: UserDto | undefined) => {
  return (
    item.forAuction && !!item.quantityAvailable && !(currentUser?.exhaustedTradeLimit ?? false)
  );
};

export const getIsOnlyShowcaseItem = (
  item: ShowcaseItemDto,
  owner: Pick<UserDto, 'canReceivePayment' | 'exhaustedTradeLimit'>,
  currentUser: UserDto | undefined,
) => {
  return (
    !getIsBuyableItem(item, owner, currentUser) &&
    !getIsCashOfferableItem(item, currentUser) &&
    !getIsTradeableItem(item, currentUser) &&
    !getIsAuctionableItem(item, currentUser)
  );
};

export const getStartTradeRouteForTradeType = (tradeType: TradeOfferType, isGuest?: boolean) => {
  switch (tradeType) {
    case TradeOfferType.BUY:
    case TradeOfferType.CASH_OFFER:
    case TradeOfferType.AUCTION:
      return routes.offers.review({ isGuest });
    case TradeOfferType.TRADE:
      return routes.offers.root;
    case TradeOfferType.WANTED_ITEM_OFFER:
      return routes.offers.root;
    default:
      throw new UnreachableCaseError(tradeType);
  }
};

export const getIsOfferDraftForCounter = (
  offerDraft: OfferDraftDto,
): offerDraft is Merge<OfferDraftDto, { key: number }> => {
  return typeof offerDraft.key === 'number';
};

export const getIsReceivedItems = ({ currentUserId, offer }: OfferAndCurrentUserId) => {
  return offer.deliveryReceives.some((receive) => receive.userId === currentUserId);
};

export const getConfirmReceiving = (params: OfferAndCurrentUserId) => {
  const { offer } = params;
  if (offer.status !== TradeOfferStatus.ACCEPTED) return false;

  if (!getIsReceivingItems(params)) return false;
  if (getIsReceivedItems(params)) return false;
  return true;
};

export function getCurrentUserShippingDetails({ currentUserId, offer }: OfferAndCurrentUserId) {
  if (currentUserId === offer.fromUser.id) {
    return offer.delivery?.fromUserDetails ?? null;
  } else {
    return offer.delivery?.toUserDetails ?? null;
  }
}

export const getItemsTotalShipping = (items: { priceShipping: number | null }[]) => {
  let shippingSum = 0;
  items.forEach((item) => {
    shippingSum += item.priceShipping || 0;
  });
  return shippingSum;
};

export const getItemsTotalPriceWithShippingAndDiscount = (
  items: { price: number | null; priceShipping: number | null }[],
  discount: CouponDiscountDto,
) => {
  const totalSum = items.reduce(
    (acc, item) => acc + (item.price ?? 0) + (item.priceShipping ?? 0),
    0,
  );
  const totalSelfPrice = items.reduce((acc, curr) => acc + (curr.price ?? 0), 0);

  if (discount.type === 'flat') {
    const discountSum = Math.max(0, discount.amountInCents);
    return totalSum - Math.min(totalSelfPrice, discountSum);
  }
  if (discount.type === 'percentage') {
    const discountSum = Math.floor(totalSelfPrice * discount.amountPercentage);
    return totalSum - discountSum;
  }
  throw new UnreachableCaseError(discount);
};

export const getItemsTotalPriceWithShipping = (
  items: { price: number | null; priceShipping: number | null }[],
) => {
  let shippingSum = 0;
  items.forEach((item) => {
    shippingSum += (item.price || 0) + (item.priceShipping || 0);
  });
  return shippingSum;
};

export const getItemsTotalPriceWithoutShipping = (items: { price: number }[]) => {
  return items.reduce((acc, item) => acc + item.price, 0);
};

export const getIsPriceShippingMatters = pipe(getIsTrade, not);

export const getItemsTotalPriceForOffer = ({
  offer,
  items,
}: {
  offer: { type: TradeOfferType };
  items: { price: number; priceShipping: number | null }[];
}) => {
  return (
    getIsPriceShippingMatters(offer)
      ? getItemsTotalPriceWithoutShipping
      : getItemsTotalPriceWithShipping
  )(items);
};

export const subtractProcessingFee = (amountInCents: number) =>
  amountInCents - Math.ceil((amountInCents * SUBTRACT_PERCENT_FOR_STRIPE_FEES) / 100);

export const getIsInstantPurchase = (offer: Pick<TradeOfferDto, 'isInstantPurchase'>) =>
  offer.isInstantPurchase;
export const getIsTaxIncluded = (params: OfferAndCurrentUserId) => {
  const { offer, currentUserId } = params;
  return offer.payments.some(
    (payment) => payment.isTaxIncluded && payment.userId === currentUserId,
  );
};

export const getIsOfferActive = ({ status }: { status: TradeOfferStatus }) => {
  switch (status) {
    case TradeOfferStatus.ABORTED:
    case TradeOfferStatus.CANCELED:
    case TradeOfferStatus.DECLINED:
    case TradeOfferStatus.EXPIRED:
    case TradeOfferStatus.INVALID:
      return false;
    case TradeOfferStatus.DONE:
    case TradeOfferStatus.ACCEPTED:
    case TradeOfferStatus.PAYMENT_PENDING:
    case TradeOfferStatus.PROPOSED:
    case TradeOfferStatus.WAITING_DELIVERY_AGREEMENT:
    case TradeOfferStatus.COUNTERED:
      return true;
    default:
      throw new UnreachableCaseError(status);
  }
};
export const getIsCouponFormShown = ({
  offer,
  currentUserId,
}: {
  offer: TradeOfferDto;
  currentUserId: number;
}) => {
  return (
    getIsOfferActive(offer) &&
    !offer.funds[0]?.discountInCents &&
    getIsOfferStartedByMe({ currentUserId, offer }) &&
    offer.funds.length === 1 && // HACK: coupons not supported for charities
    offer.status === TradeOfferStatus.PAYMENT_PENDING
  );
};
