import { ShowcaseItemSearchDtoWithPrice } from '@/api/showcaseItems/showcaseItems.dto';
import { TradeOfferType } from '@/types';
import { calcTotalPrice } from '@/utils/calcPrice';
import { formatCents } from '@/utils/formatCents';
import { pluralize } from '@/utils/pluralize';
import UnreachableCaseError from '@/utils/UnreachableCaseError';
import cn from 'classnames';
import { OfferDraftDto } from '../../offerDrafts.store';
import {
  getIsOfferDraftForCounter,
  getIsPriceShippingMatters,
  getIsTrade,
  getIsWantedItemOffer,
  getOfferOtherUserId,
  pickItems,
} from '../../tradeOffer.utils';
import { OfferItemsList } from '../OfferItemsList';

const getSectionTitle = (type: TradeOfferType, ownItems: boolean) => {
  switch (type) {
    case TradeOfferType.TRADE:
      return ownItems ? 'Items you are trading' : 'Items being traded to you';
    case TradeOfferType.CASH_OFFER:
      return 'Items for Cash Offer';
    case TradeOfferType.BUY:
    case TradeOfferType.AUCTION:
      return 'Items you are buying';
    case TradeOfferType.WANTED_ITEM_OFFER:
      return ownItems ? 'Your items in offer' : 'Current items you are offering for';
    case TradeOfferType.AUCTION:
      return 'Auction';
    default:
      throw new UnreachableCaseError(type);
  }
};

export const AddItemsFloatingPanelSection = ({
  firstSection,
  offerDraft,
  currentUserId,
  isChoosingOwnItems,
  itemsRequested,
  itemsOffered,
  className,
  isAddingOwnItems,
  isAddingTheirItems,
}: {
  firstSection: boolean;
  offerDraft: OfferDraftDto;
  currentUserId: number;
  isChoosingOwnItems: boolean;
  itemsRequested: ShowcaseItemSearchDtoWithPrice[];
  itemsOffered: ShowcaseItemSearchDtoWithPrice[];
  className: string;
  isAddingOwnItems: boolean;
  isAddingTheirItems: boolean;
}) => {
  const ownItems = getIsOfferDraftForCounter(offerDraft) ? firstSection : !firstSection;
  const isTradeType = getIsTrade(offerDraft);
  const items = pickItems({
    ownItems,
    requested: itemsRequested,
    offered: itemsOffered,
    currentUserId,
    offer: offerDraft,
  });

  const showAddButton = ownItems ? !isAddingOwnItems : !isAddingTheirItems;

  const itemOwnerId = isChoosingOwnItems
    ? currentUserId
    : getOfferOtherUserId({ currentUserId, offer: offerDraft });

  return (
    <div
      className={cn('sm:px-2 md:px-6 pb-3 h-full', className, isTradeType ? 'sm:w-1/2' : 'w-full')}
    >
      <div className="flex flex-col gap-y-1 md:flex-row items-center justify-between pb-6 sm:pb-2">
        <div className="font-semibold text-gray-01 sm:pr-3">
          {getSectionTitle(offerDraft.type, ownItems)}:{' '}
          <span className="whitespace-nowrap">{pluralize('item', items.length, true)}</span>
        </div>
        {!getIsWantedItemOffer(offerDraft) && (
          <div>
            <span className="font-semibold text-gray-79">Total:</span>
            <span className="font-bold text-lg text-gray-01 ml-2">
              {formatCents(calcTotalPrice(items))}
            </span>
          </div>
        )}
      </div>

      <OfferItemsList
        currentUserId={currentUserId}
        ownItems={ownItems}
        items={items}
        itemOwnerId={itemOwnerId}
        showPriceShipping={getIsPriceShippingMatters(offerDraft)}
        forFloatingPanel
        manageItems={{
          offerDraft,
          canAdd: false,
          canRemove: true,
          showAddButton,
        }}
      />
    </div>
  );
};
