import CustomNextLink from '@/components/CustomNextLink';
import { useCurrentUserId } from '@/features/user/currentUser.service';
import routes from '@/routes/routes';
import { scrollToTop } from '@/utils/scrollToTop';
import { getCurrentPath } from '@/utils/url';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { AiFillCaretDown } from 'react-icons/ai';
import { useOfferDraftItemsQuery } from '../../offerDrafts.service';
import {
  OfferDraftDto,
  removeOfferDraftFromStore,
  useOfferDraftFromStore,
} from '../../offerDrafts.store';
import {
  getIsBuyOffer,
  getIsCashOffer,
  getIsOfferDraftForCounter,
  getIsTrade,
  getIsWantedItemOffer,
} from '../../tradeOffer.utils';
import {
  AddItemsFloatingPanelState,
  saveAddItemsFloatingPanelState,
  useAddItemsFloatingPanelState,
} from './addItemsFloatingPanel.store';
import { AddItemsFloatingPanelSection } from './AddItemsFloatingPanelSection';

const HIDE_PANEL_ON_ROUTES = [
  routes.offers.review(),
  '/trade/[slug]/counter',
  '/user/[userId]/showcase/item/[itemId]',
];

const addOwnItemsRoute = '/trade/[slug]/add-my-items';
const addTheirItemsRoute = '/trade/[slug]/add-their-items';

export const AddItemsFloatingPanel = () => {
  const state = useAddItemsFloatingPanelState();
  const currentUserId = useCurrentUserId();
  const router = useRouter();

  const match = HIDE_PANEL_ON_ROUTES.includes(router.pathname);

  if (match || !state || !currentUserId) return null;

  return <AddItemsFloatingPanelWithState currentUserId={currentUserId} state={state} />;
};

export const AddItemsFloatingPanelWithState = ({
  currentUserId,
  state,
}: {
  currentUserId: number;
  state: AddItemsFloatingPanelState;
}) => {
  const router = useRouter();
  const offerDraft = useOfferDraftFromStore(state.offerDraftKey);

  if (
    !offerDraft ||
    ((getIsBuyOffer(offerDraft) || getIsCashOffer(offerDraft)) &&
      router.pathname === '/trade/[slug]/add-their-items')
  )
    return null;

  return (
    <AddItemsFloatingPanelWithStateAndOfferDraft
      currentUserId={currentUserId}
      state={state}
      offerDraft={offerDraft}
    />
  );
};

const AddItemsFloatingPanelWithStateAndOfferDraft = ({
  currentUserId,
  state,
  offerDraft,
}: {
  currentUserId: number;
  state: AddItemsFloatingPanelState;
  offerDraft: OfferDraftDto;
}) => {
  const offerDraftItemsQuery = useOfferDraftItemsQuery(offerDraft);
  const router = useRouter();
  const currentPath = getCurrentPath();
  const match = [addOwnItemsRoute, addTheirItemsRoute].includes(currentPath);
  if (!offerDraftItemsQuery.data) return null;
  const { itemsRequested, itemsOffered } = offerDraftItemsQuery.data;
  const shouldRedirectOnCancel = Boolean(match);
  const isAddingOwnItems = router.pathname === addOwnItemsRoute;
  const isAddingTheirItems = getIsWantedItemOffer(offerDraft)
    ? true
    : router.pathname === addTheirItemsRoute;
  const sectionClass = cn(
    'transition-all duration-300 pt-6',
    state.openOnDesktop ? 'sm:pt-3' : 'sm:pt-1',
  );

  const toggleDesktop = () => {
    saveAddItemsFloatingPanelState(
      (data) =>
        data && {
          ...data,
          openOnDesktop: !data.openOnDesktop,
        },
    );
  };

  const toggleMobile = () => {
    saveAddItemsFloatingPanelState(
      (data) =>
        data && {
          ...data,
          openOnMobile: !data.openOnMobile,
        },
    );
    scrollToTop();
  };

  const cancel = () => {
    if (shouldRedirectOnCancel) {
      router.push(routes.tradeOffers());
    }
    removeOfferDraftFromStore(offerDraft.key);
    saveAddItemsFloatingPanelState(() => null);
  };

  return (
    <div
      className={cn(
        'bottom-0 z-[1001] sticky',
        state.openOnMobile && 'border-gray-cb border-t',
        'sm:border-0 sm:fixed sm:left-0 sm:right-0 sm:bottom-5 sm:container-x',
      )}
    >
      <div
        className={cn(
          'bg-gray-f9 rounded border border-gray-cb shadow',
          'border-t-0',
          'sm:border-t sm:relative',
        )}
      >
        <div className="hidden sm:block">
          <button
            type="button"
            className={cn(
              'absolute -top-2.5 -right-2.5 w-5 h-5 rounded-full bg-gray-cb text-white text-sm flex-center duration-300 transition transform hover:bg-gray-bd',
              !state.openOnDesktop && 'rotate-180',
            )}
            onClick={toggleDesktop}
          >
            <AiFillCaretDown className="relative top-px" />
          </button>
        </div>

        <div
          className={cn(
            'sm:flex divide-y sm:divide-y-0 sm:divide-x divide-gray-cb overflow-hidden duration-300 transition-all',
            state.openOnDesktop ? 'sm:h-[210px] md:h-[194px]' : 'sm:h-16 md:h-10',
            !state.openOnMobile && 'hidden',
          )}
        >
          <AddItemsFloatingPanelSection
            firstSection={true}
            offerDraft={offerDraft}
            currentUserId={currentUserId}
            isChoosingOwnItems={state.ownItemsActive}
            itemsRequested={itemsRequested}
            itemsOffered={itemsOffered}
            className={sectionClass}
            isAddingOwnItems={isAddingOwnItems}
            isAddingTheirItems={isAddingTheirItems}
          />
          {getIsTrade(offerDraft) && (
            <AddItemsFloatingPanelSection
              firstSection={false}
              offerDraft={offerDraft}
              currentUserId={currentUserId}
              isChoosingOwnItems={state.ownItemsActive}
              itemsRequested={itemsRequested}
              itemsOffered={itemsOffered}
              className={sectionClass}
              isAddingOwnItems={isAddingOwnItems}
              isAddingTheirItems={isAddingTheirItems}
            />
          )}
        </div>
        <div className="border-t border-gray-cb py-3 px-6 flex gap-x-8 justify-center sm:justify-end items-center relative">
          <button type="button" className="btn-cancel" onClick={cancel}>
            Cancel Trade
          </button>
          <button
            type="button"
            className="sm:hidden btn btn-primary absolute -top-4 text-xs"
            onClick={toggleMobile}
          >
            {state.openOnMobile ? 'Hide items' : 'Show items'}
          </button>
          <CustomNextLink
            to={
              getIsOfferDraftForCounter(offerDraft)
                ? routes.offers.counter(offerDraft.key)
                : routes.offers.review()
            }
            className="btn-new btn-primary"
            onClick={scrollToTop}
          >
            Review Offer
          </CustomNextLink>
        </div>
      </div>
    </div>
  );
};
