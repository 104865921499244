import cn from 'classnames';

type Props = {
  message?: string;
  position?: 'left' | 'right';
};

const CustomTooltip = ({ message, position = 'right' }: Props) => {
  if (message)
    return (
      <div className={cn('tooltip', position === 'left' ? 'tooltip-left' : 'tooltip-right')}>
        <p>{message}</p>
      </div>
    );
  else return null;
};

export default CustomTooltip;
