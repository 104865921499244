import { DateDto, UserLinkDto } from '@/types';
import dayjs from 'dayjs';
import { Merge } from 'type-fest';
import { AuctionBidDto } from './auctionBid.dto';

export type AuctionInfoStatus =
  | 'waiting'
  | 'started'
  | 'payment-pending-from-winner'
  | 'winner-refused-to-pay'
  | 'winner-requested-cancel'
  | 'waiting-for-next-winner'
  | 'closed-without-winner'
  | 'closed-with-winner'
  | 'waiting-for-seller-to-handle-payment-refusal';

export type AuctionInfoType = 'regular' | 'fantasy' | 'charity';

export type AuctionInfoFantasyType = 'fantasy' | 'head-to-head' | 'legacy';

export type _AuctionInfoDto = {
  id: number;
  showcaseItemId: number;

  status: AuctionInfoStatus;
  type: AuctionInfoType;

  /** Must be present for `fantasy` type. */
  fantasyType?: AuctionInfoFantasyType;

  /** Must be present for `fantasy` type. Can be null if fantasy auction is not fulfilled yet. */
  fantasyDiscountAmountInCents?: number | null;

  /** Must be present for `charity` type. Can be null. */
  charityBeneficiary?: null | UserLinkDto;

  startingPrice: number;
  currentPrice: number | null;
  nextPrice: number;
  reservePriceHasBeenReached: boolean;

  /**
   * `undefined` for auction host and for guests.
   * `null` if current user hasn't bid on this auction.
   */
  currentUserBid?: null | AuctionBidDto;

  totalBids: number;
  totalBidders: number;

  /** Value 0.01 means 1%. */
  increaseRate: number;

  auctionRules: string | null;

  /**
   * Number of milliseconds before start if auction is about to start.
   * Number of milliseconds before end if auction is about to end.
   * This is needed because relying at `endAt` at the client-side to calculate time left is errorprone
   * due to local time being possibly not in sync with server's time.
   */
  msLeft: number | null;

  startAt: DateDto;
  endAt: DateDto;
};

export type NormalizeAuctionInfoDto<T extends _AuctionInfoDto> = Merge<
  T,
  {
    // msLeft is used to adjust startAt/endAt to match local time and then we can get rid of it
    startAt: Date;
    endAt: Date;
    msLeft: undefined;
  }
>;

export type AuctionInfoDto = NormalizeAuctionInfoDto<_AuctionInfoDto>;

export type _AuctionInfoFullDto = _AuctionInfoDto & {
  reservePrice: number | null;
  winner: null | UserLinkDto;
  priceHistory: AuctionPriceChangeDto[];
  price2ndChanceOffer: number | null;
  autoRestart: boolean;
};

export type AuctionInfoFullDto = NormalizeAuctionInfoDto<_AuctionInfoFullDto>;

export function isAuctionInfoFullDto(
  input: AuctionInfoDto | AuctionInfoFullDto,
): input is AuctionInfoFullDto {
  return 'reservePrice' in input;
}

export type AuctionPriceChangeDto = {
  isRealChange: boolean;
  currentPrice: number;
  updatedAt: DateDto;
  user: UserLinkDto;
};

export type AuctionInfoCreateDto = {
  type: AuctionInfoType;
  fantasyType?: AuctionInfoFantasyType;
  charityBeneficiaryUsername?: string;
  startAt: string;
  startingPrice: number;
  reservePrice: number | null;
  endAt: string;
  auctionRules: string | null;
  increaseRate: number;
  autoRestart: boolean;
};

export function normalizeAuctionInfoDto<T extends _AuctionInfoDto>(
  auctionInfo: T,
): NormalizeAuctionInfoDto<T> {
  return {
    ...auctionInfo,
    ...getLocalStartEndAuction(auctionInfo),
    msLeft: undefined,
  };
}

function getLocalStartEndAuction(auctionInfo: _AuctionInfoDto): {
  startAt: Date;
  endAt: Date;
} {
  if (auctionInfo.status === 'waiting' && auctionInfo.msLeft != null) {
    return {
      startAt: dayjs().add(auctionInfo.msLeft, 'ms').toDate(),
      endAt: new Date(auctionInfo.endAt),
    };
  }

  if (auctionInfo.status === 'started' && auctionInfo.msLeft != null) {
    return {
      startAt: new Date(auctionInfo.startAt),
      endAt: dayjs().add(auctionInfo.msLeft, 'ms').toDate(),
    };
  }

  return {
    startAt: new Date(auctionInfo.startAt),
    endAt: new Date(auctionInfo.endAt),
  };
}
