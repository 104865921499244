import Link from 'next/link';

/** @deprecated */
export default function CustomNextLink({
  to,
  className,
  children,
  onClick,
}: {
  to: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <Link href={to}>
      <a className={className} onClick={onClick ? onClick : () => {}}>
        {children}
      </a>
    </Link>
  );
}
