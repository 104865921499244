import CustomNextLink from '@/components/CustomNextLink';
import routes from '../../../../../routes/routes';
import { categoryLinkClassNames } from '../styles';

const CategoryLinks = () => {
  return (
    <>
      <CustomNextLink to={routes.sport('all')} className={categoryLinkClassNames}>
        Sports
      </CustomNextLink>
      <CustomNextLink to={routes.sport('Olympics')} className={categoryLinkClassNames}>
        Olympics
      </CustomNextLink>
      <CustomNextLink to={routes.sport('Entertainment')} className={categoryLinkClassNames}>
        Entertainment
      </CustomNextLink>
      <CustomNextLink
        to={routes.sport('Presidential-Historical')}
        className={categoryLinkClassNames}
      >
        Historical
      </CustomNextLink>
      <CustomNextLink to={routes.sport('Other')} className={categoryLinkClassNames}>
        Other
      </CustomNextLink>
    </>
  );
};

export default CategoryLinks;
