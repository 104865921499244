import toSafeNumber from './toSafeNumber';

export const formatCents = (
  cents: number | null | undefined,
  { prefix = '$', naValue = 'N/A' }: { prefix?: string; naValue?: string } = {},
): string => {
  if (cents == null) {
    return naValue;
  } else {
    return `${prefix}${(cents / 100).toFixed(2)}`;
  }
};

export const formatCentsInputNumber = (cents: number | null | undefined): string => {
  return formatCents(cents, { naValue: '' });
};

export const parseCents = (input: undefined | null | string): null | number => {
  if (input == null) return null;
  const cand = toSafeNumber(input.replace(/\$/g, '').replace(/\./g, ''));
  if (cand == null) return null;
  return cand;
};

export const parseCentsInputNumber = (input: undefined | null | string): number => {
  return parseCents(input) ?? 0;
};
