import { useShowcaseItemsCount } from '@/api/showcaseItems/showcaseItems.api';
import { useUsersCount } from '@/api/user/user.api';
import { CustomRadixSelect } from '@/components/@next/CustomSelect/CustomRadixSelect';
import routes from '@/routes/routes';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import React, { useCallback, useRef, useState } from 'react';
import { BiChevronDown, BiSearch } from 'react-icons/bi';
import { useClickAway } from 'react-use';
import SearchCount from './SearchCount';

const typeOfSearchOptions = [
  { value: 'items', label: 'Items' },
  { value: 'members', label: 'Members' },
];

const Search = () => {
  const router = useRouter();
  const params = new URLSearchParams(location.search);
  const isSearchMember = router.pathname === routes.showcases;
  const [search, setSearch] = useState(
    () => new URLSearchParams(location.search).get('search') ?? '',
  );
  const [typeOfSearch, setTypeOfSearch] = useState<string>(isSearchMember ? 'members' : 'items');
  const [isSearchCountOpen, setIsSearchCountOpen] = useState(false);

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    params.set('search', search);
    if (typeOfSearch === 'members') {
      router.push(`${routes.showcases}?${params.toString()}`);
      return;
    }
    router.push(`${routes.search()}?${params.toString()}`);
  };

  const debounceSearchQuery = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 1000),
    [],
  );

  const { data: showcaseCount, isLoading: showcaseLoading } = useShowcaseItemsCount(
    { search: search },
    { enabled: search.length > 2 && typeOfSearch === 'items' },
  );
  const { data: userCount, isLoading: userIsLoading } = useUsersCount(
    { search: search },
    { enabled: search.length > 2 && typeOfSearch === 'members' },
  );

  const isSearchLoading = showcaseLoading || userIsLoading || search.length <= 2;

  const searchCountPanelRef = useRef<HTMLFormElement>(null);
  useClickAway(searchCountPanelRef, () => setIsSearchCountOpen(false));

  return (
    <form
      onSubmit={submitSearch}
      ref={searchCountPanelRef}
      className={`flex border rounded-md border-gray-d9 h-12 flex-1 w-full lg:max-w-[575px] ${
        isSearchCountOpen ? 'rounded-br-none' : ''
      }`}
    >
      <CustomRadixSelect
        defaultValue={isSearchMember ? typeOfSearchOptions[1].value : typeOfSearchOptions[0].value}
        placeholder="Items or Members"
        values={typeOfSearchOptions}
        className="!w-auto"
        selectClassName="!border-0 !border-r !rounded-r-none !bg-gray-f9 h-full"
        caretIcon={<BiChevronDown size={25} className="text-gray-cc" />}
        onChange={(e) => {
          e && setTypeOfSearch(e);
        }}
      />

      <label className="flex w-full items-center px-4 gap-x-2.5 relative">
        <BiSearch size={24} className="text-gray-8a" />
        <input
          defaultValue={search}
          type={'text'}
          className="border-0 w-full self-stretch text-base placeholder:text-gray-8a placeholder:text-base"
          placeholder="Search Items, Members..."
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 2) setIsSearchCountOpen(true);
            else setIsSearchCountOpen(false);
            debounceSearchQuery(e.target.value);
          }}
          onFocus={(e) => {
            if (e.target.value && e.target.value.length > 2) setIsSearchCountOpen(true);
          }}
        />
        {isSearchCountOpen && (
          <SearchCount
            userCount={userCount ?? 0}
            showcaseCount={showcaseCount ?? 0}
            isLoading={isSearchLoading}
            searchQuery={search}
            typeOfSearch={typeOfSearch}
          />
        )}
      </label>
      <input type="submit" hidden />
    </form>
  );
};

export default Search;
