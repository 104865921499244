import { ShowcaseItemSearchDtoWithPrice } from '@/api/showcaseItems/showcaseItems.dto';
import routes from '@/routes/routes';
import { TradeOfferItemDto } from '@/types';
import { PlusCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import Link from 'next/link';
import { OfferDraftDto } from '../offerDrafts.store';
import { pickItems } from '../tradeOffer.utils';
import { OfferItem } from './OfferItem';
export const OfferItemsList = ({
  currentUserId,
  ownItems,
  items,
  showPriceShipping,
  forFloatingPanel,
  className,
  itemOwnerId,
  manageItems,
}: {
  currentUserId: number;
  ownItems: boolean;
  items: ShowcaseItemSearchDtoWithPrice[] | TradeOfferItemDto[];
  showPriceShipping: boolean;
  forFloatingPanel?: boolean;
  className?: string;
  itemOwnerId: number;
  manageItems?: {
    offerDraft: OfferDraftDto;
    canAdd: boolean;
    canRemove: boolean;
    showAddButton: boolean;
  };
}) => {
  const itemClass = forFloatingPanel
    ? 'px-1.5 w-1/3 sm:px-0 sm:w-[88px]'
    : `${items.length === 1 ? 'w-full' : 'w-[154px]'} sm:w-[184px]`;

  const manageItemsAndSavedItemIds = manageItems && {
    ...manageItems,
    savedItemIds: pickItems({
      ownItems,
      requested: manageItems.offerDraft.itemsRequestedIds,
      offered: manageItems.offerDraft.itemsOfferedIds,
      currentUserId,
      offer: manageItems.offerDraft,
    }),
  };

  return (
    <div
      className={cn(
        'flex flex-wrap items-center sm:justify-start',
        forFloatingPanel
          ? 'px-2 gap-y-6 sm:gap-x-6 sm:overflow-auto sm:pt-2 sm:h-[126px]'
          : 'gap-1 sm:gap-6',
        className,
      )}
    >
      {items.map((item) => (
        <OfferItem
          key={item.id}
          currentUserId={currentUserId}
          ownItems={ownItems}
          item={item}
          itemOwnerId={itemOwnerId}
          showPriceShipping={showPriceShipping}
          forFloatingPanel={forFloatingPanel}
          className={itemClass}
          manageItems={manageItemsAndSavedItemIds}
        />
      ))}

      {manageItems?.showAddButton ? (
        <Link
          href={(ownItems ? routes.offers.addOwnItems : routes.offers.addTheirItems)(
            manageItems.offerDraft.key,
          )}
        >
          <a className={itemClass} onClick={() => window.scrollTo(0, 0)}>
            <div className="relative pb-[100%]">
              <div className="absolute inset-0 rounded shadow-sm bg-amber-f8 flex-center flex-col duration-300 transition">
                <PlusCircleFilled className="text-xl text-primary mb-1" />
                <div className="font-semibold">
                  {ownItems ? 'Add Your items' : 'Add more items'}
                </div>
              </div>
            </div>
          </a>
        </Link>
      ) : (
        <p className="font-bold text-danger">
          {ownItems
            ? 'Please click on all items desired from your Showcase to add to this deal.'
            : 'Please click on all items desired from their Showcase to add to this deal.'}
        </p>
      )}
    </div>
  );
};
