import { useUnreadNotificationsCountQuery } from '@/api/notification/notification.api';
import { useTradeCountQuery } from '@/api/tradeOffer/tradeOffer.api';
import { logout } from '@/features/auth/auth.service';
import { useIsAuthenticated } from '@/features/user/currentUser.service';
import { useApiWebSocket } from '@/hooks/useApiWebSocket';
import routes from '@/routes/routes';
import { UserFullDto } from '@/types';
import { getProfilePhoto } from '@/utils/profilePhoto';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Badge } from 'antd';
import Link from 'next/link';
import { useState } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { TbBell } from 'react-icons/tb';
export const UserDropdown = ({ currentUser }: { currentUser: UserFullDto }) => {
  // HACK: Somehow on logout this component gets rerendered with old props despite
  // the fact that `currentUser` is already `undefined` in the parent component
  // and this component shouldn't be rendered AT ALL.
  // This causes the query to fire which then fails with an error because it's not
  // supposed to be fired for anonymous users. Hence here we are directly using
  // `useIsAuthenticated` that gets data from local storage because props are not
  // up-to-date.
  const isAuthenticated = useIsAuthenticated();
  const unreadNotificationsCount = useUnreadNotificationsCountQuery({
    enabled: isAuthenticated,
  });
  const menuItemClass = 'text-gray-01 relative flex items-center text-base';

  useApiWebSocket({
    onMessage: (message) => {
      if (message.type !== 'notification') return;

      unreadNotificationsCount.refetch();
    },
  });
  const [tradeCountValue, setTradeCartValue] = useState<number>(0);
  useTradeCountQuery({
    onSuccess: (data) => {
      const count = Object.values(data || {}).reduce((acc, v) => acc + v, 0);
      setTradeCartValue(count);
    },
    enabled: isAuthenticated,
  });
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div>
          <img
            className="w-[43px] h-[43px] rounded-full"
            src={getProfilePhoto(currentUser, 'original')}
            alt="profile-picture"
          />
        </div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className="bg-white p-5 rounded-md shadow-lg" sideOffset={5}>
          <DropdownMenu.Item>
            <Link href={routes.user()}>
              <a>
                <span className="text-blue-21 border-b border-blue-21">My Profile</span>
              </a>
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item className="mt-4">
            <Link href={routes.showcase({ userId: currentUser.id })}>
              <a className={menuItemClass}>My Showcase</a>
            </Link>{' '}
          </DropdownMenu.Item>
          <DropdownMenu.Item className="mt-4">
            <Link href={routes.currentBids()}>
              <a className={menuItemClass}>My Current Bids</a>
            </Link>{' '}
          </DropdownMenu.Item>
          <DropdownMenu.Item className="mt-4">
            <Link href={routes.tradeOffers()}>
              <Badge count={tradeCountValue} color="#2176ff">
                <a className={menuItemClass}>Offers</a>
              </Badge>
            </Link>{' '}
          </DropdownMenu.Item>
          <DropdownMenu.Item className="mt-4">
            <Link href={routes.notifications()}>
              <a className={menuItemClass}>
                Notifications
                <Badge count={unreadNotificationsCount.data?.count ?? 0} color="#2176ff">
                  <TbBell size={24} />
                </Badge>
              </a>
            </Link>{' '}
          </DropdownMenu.Item>
          <DropdownMenu.Item className="mt-4">
            <span onClick={logout} className="flex flex-row w-full justify-between">
              <span>Logout</span>
              <FaSignOutAlt size={24} />
            </span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
