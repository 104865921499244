import React, { useEffect } from 'react';
import Layout from './components/@next/layout/Layout';
import { useAuthService } from './features/auth/auth.service';
import { patchAntdMessageError } from './utils/patchAntdMessageError';

export default function NextApp({
  children,
  noLayout,
}: {
  children: React.ReactNode;
  noLayout?: boolean;
}) {
  useAuthService();

  useEffect(() => {
    const unpatch = patchAntdMessageError();
    return () => {
      unpatch();
    };
  }, []);

  return <div className="App">{noLayout ? <>{children}</> : <Layout>{children}</Layout>}</div>;
}
