import {
  CLOSE_CHAT_BOX,
  FINISH_TRANSFORMING,
  OPEN_CHAT_BOX,
  RESET_CHAT_STATE,
  SET_ALL_CONVERSATIONS,
  SET_CONVERSATION,
  SET_CONVERSATIONS_WITH_USER,
  SET_START_CONVERSATION,
  SET_WINDOW_PARAMS,
} from '../actions/actionTypes';
import { ConversationDTO, TempConversationDTO, UsersConversationDTO } from '../api/chat/chat.dto';
import { IAction } from '../types';

export type AllConversationsWindowParams = {
  index: 0;
  archived?: boolean;
};
export type ConversationsWithUserWindowParams = {
  index: 1;
  otherUser: UsersConversationDTO;
  archived?: boolean;
};
export type ConversationWindowParams = {
  index: 2;
  conversation: ConversationDTO;
  otherUser: UsersConversationDTO;
  archived?: boolean;
};
export type StartConversationWindowParams = {
  index: 2;
  tempConversation: TempConversationDTO;
  otherUser: UsersConversationDTO;
};

export type ChatWindowParams =
  | AllConversationsWindowParams
  | ConversationsWithUserWindowParams
  | ConversationWindowParams
  | StartConversationWindowParams;

export type ChatState = {
  isChatBoxOpen: boolean;
  isTransforming: boolean;
  windowParams: ChatWindowParams;
};

const initialState: ChatState = {
  isChatBoxOpen: false,
  isTransforming: false,
  windowParams: { index: 0 },
};

const chatReducer = (state = initialState, action: IAction): ChatState => {
  switch (action.type) {
    case RESET_CHAT_STATE:
      return initialState;
    case OPEN_CHAT_BOX:
      if (!state.isChatBoxOpen) {
        return { ...state, isChatBoxOpen: true, isTransforming: true };
      }
      return state;

    case CLOSE_CHAT_BOX:
      if (state.isChatBoxOpen) {
        return { ...state, isChatBoxOpen: false, isTransforming: true };
      }
      return state;
    case FINISH_TRANSFORMING:
      return { ...state, isTransforming: false };
    case SET_ALL_CONVERSATIONS:
      return { ...state, windowParams: { index: 0, ...action.payload } };
    case SET_CONVERSATIONS_WITH_USER:
      return { ...state, windowParams: action.payload };
    case SET_CONVERSATION:
      return { ...state, windowParams: action.payload };
    case SET_START_CONVERSATION:
      return { ...state, windowParams: action.payload };
    case SET_WINDOW_PARAMS:
      return { ...state, windowParams: action.payload };
    default:
      return state;
  }
};

export default chatReducer;
