import { getCurrentPath } from '@/utils/url';
import { useRouter } from 'next/router';
import { BiMenu, BiUser } from 'react-icons/bi';
import routes from '../../../../../routes/routes';
import StandaloneLoader from '../../../StandaloneLoader';

const SearchCount = ({
  searchQuery,
  userCount,
  showcaseCount,
  isLoading,
  typeOfSearch,
}: {
  searchQuery: string | undefined;
  userCount: number;
  showcaseCount: number;
  isLoading: boolean;
  typeOfSearch: string;
}) => {
  const router = useRouter();
  const onResultClicked = (isMembersSearch: boolean) => {
    const params = new URLSearchParams(location.search);
    params.set('search', searchQuery ?? '');
    if (isMembersSearch) {
      router.push(`${routes.showcases}?${params.toString()}`);
    } else {
      const currentPath = getCurrentPath();
      const isInItemsPage = currentPath.startsWith('/categories');
      router.push(`${isInItemsPage ? currentPath : routes.sport('all')}?${params.toString()}`);
    }
  };

  return (
    <div className="absolute top-full w-[calc(100%+2px)] -left-3 overflow-hidden bg-white z-[100] border border-gray-cb mr-[184px] mt-0 mb-0 ml-[11px] rounded-b-[20px] flex flex-col justify-center items-start p-4 pt-2">
      {isLoading && (
        <div className="w-full mx-4 justify-between items-start font-semibold">
          <span>Loading search result ...</span>
          <StandaloneLoader />
        </div>
      )}
      {!isLoading && (
        <div className="w-full flex flex-col justify-start items-start ">
          <span className="w-full text-left text-lg p-1 border-b mb-1 font-semibold">
            Search results for `{searchQuery}`
          </span>
          <div className="w-full text-sm flex flex-col justify-start gap-1 items-start">
            {typeOfSearch === 'items' && (
              <div
                onClick={() => onResultClicked(false)}
                className="w-full hover:bg-accent-light cursor-pointer flex flex-row justify-start items-center gap-2"
              >
                <BiMenu size={25} className="text-black" />
                <span>
                  {showcaseCount ?? 0} Item{showcaseCount && showcaseCount > 1 ? 's' : ''}
                </span>
              </div>
            )}
            {typeOfSearch === 'members' && (
              <div
                onClick={() => onResultClicked(true)}
                className="w-full hover:bg-accent-light cursor-pointer flex flex-row justify-start items-center gap-2"
              >
                <BiUser size={25} className="text-black" />
                <span>
                  {userCount ?? 0} Member{userCount && userCount > 1 ? 's' : ''}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchCount;
