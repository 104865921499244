import { ShowcaseItemDto } from '../api/showcaseItems/showcaseItems.dto';

export const calcPrice = (item: Pick<ShowcaseItemDto, 'price'>) => {
  return item.price ?? 0;
};

export const calcTotalPrice = (items: Pick<ShowcaseItemDto, 'price'>[], fundsInCents = 0) => {
  return (
    items.reduce((acc, item) => {
      return acc + calcPrice(item);
    }, 0) + fundsInCents
  );
};
